export enum FeatureGateName {
  AUDIT_LOGS_AVA = 'audit_logs_ava',
  CHANGE_MANAGEMENT_DIFF = 'change_management_diff',
  CODE_DIFF = 'code_diff',
  OPTION_VALUE_TYPE_CHANGE = 'multi_choice_option_value_type_change',
  ONE_MEDICAL_TMS_FORM_MAPPER = 'one_medical_treat-me-now_form_input_mapper',
  CUSTOM_THEME = 'custom_theme',
  CHATBOT = 'chatbot',
  ALLOW_NUMBER_QUESTION_VALIDATION = 'allow_number_question_validation',
  ALLOW_MULTI_CHOICE_QUESTION_VALIDATION = 'allow_choice_limit_in_multi-select_questions',
  AUTOMATIC_EXTENSION_DATA_VARIABLES = 'automatic-extension-data-variables',
  WORKING_HOURS = 'working_hours',
  DERIVED_DATA_POINT_MODAL = 'derived_data_point_definition_creation_modal',
  NEW_STEP_DESIGN = 'new_step_design',
  CAREFLOW_SCOPED_LIBRARY_ONLY = 'careflow_scoped_library_only',
  NEW_CAREFLOWS_PAGE = 'new_careflows_page',
  NEW_SIDEBAR_NAVIGATION = 'new_sidebar_navigation',
  FHIR_INTEGRATION = 'fhir_integration',
  NEW_DATA_SEARCH_RULE_EDITOR = 'new_datasearch_select_rule_editor',
  NEW_DATA_POINT_SELECTOR = 'new_data_point_selector',
  MONITOR_PAGE = 'monitor_page'
}
