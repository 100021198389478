/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "PaginationAndSortingPayload": [
      "ActivitiesPayload",
      "DataPointPayload",
      "OrchestrationFactsPayload",
      "PathwaysPayload",
      "PatientsPayload",
      "PublishedPathwayDefinitionsPayload"
    ],
    "Payload": [
      "ActionPayload",
      "AddIdentifierToPatientPayload",
      "AddMetadataPayload",
      "AddTrackPayload",
      "ApiCallPayload",
      "ApiCallsPayload",
      "BaselineInfoPayload",
      "CalculationResultsPayload",
      "CancelScheduledTracksPayload",
      "ChecklistPayload",
      "ClinicalNotePayload",
      "CompleteExtensionActivityPayload",
      "CreatePatientPayload",
      "CurrentUserPayload",
      "ElementsPayload",
      "EmptyPayload",
      "EmrReportPayload",
      "EvaluateFormRulesPayload",
      "ExtensionActivityRecordPayload",
      "FormPayload",
      "FormResponsePayload",
      "FormsPayload",
      "GenerateRetoolEmbedUrlPayload",
      "HostedPagesLinkPayload",
      "HostedSessionActivitiesPayload",
      "HostedSessionPayload",
      "IdentityVerificationPayload",
      "MarkMessageAsReadPayload",
      "MessagePayload",
      "OrchestrationFactsPromptPayload",
      "PathwayDataPointDefinitionsPayload",
      "PathwayPayload",
      "PatientDemographicsPayload",
      "PatientPathwaysPayload",
      "PatientPayload",
      "RetryApiCallPayload",
      "RetryWebhookCallPayload",
      "ScheduleTrackPayload",
      "ScheduledStepsPayload",
      "ScheduledTracksPayload",
      "SearchPatientsPayload",
      "StakeholdersPayload",
      "StartHostedActivitySessionPayload",
      "StartHostedPathwaySessionFromLinkPayload",
      "StartHostedPathwaySessionPayload",
      "StartPathwayPayload",
      "StartPathwayWithPatientIdentifierPayload",
      "StopTrackPayload",
      "SubmitChecklistPayload",
      "SubmitFormResponsePayload",
      "TracksPayload",
      "UpdateEmrReportStatusPayload",
      "UpdatePatientDemographicsQueryPayload",
      "UpdatePatientLanguagePayload",
      "UpdatePatientPayload",
      "WebhookCallPayload",
      "WebhookCallsPayload"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Safe date scalar that can serialize string or date */
  SafeDate: any;
};

export type ActionComponent = {
  __typename?: 'ActionComponent';
  definition_id?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ActionPayload = Payload & {
  __typename?: 'ActionPayload';
  calculationId: Scalars['String'];
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum ActionType {
  ApiCall = 'API_CALL',
  ApiCallGraphql = 'API_CALL_GRAPHQL',
  Calculation = 'CALCULATION',
  Checklist = 'CHECKLIST',
  ClinicalNote = 'CLINICAL_NOTE',
  Form = 'FORM',
  Message = 'MESSAGE',
  Plugin = 'PLUGIN',
  PushToEmr = 'PUSH_TO_EMR'
}

export type ActivitiesPayload = PaginationAndSortingPayload & {
  __typename?: 'ActivitiesPayload';
  activities: Array<Activity>;
  code: Scalars['String'];
  metadata?: Maybe<ActivityMetadata>;
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type Activity = {
  __typename?: 'Activity';
  action: ActivityAction;
  action_component?: Maybe<ActionComponent>;
  container_name?: Maybe<Scalars['String']>;
  context?: Maybe<PathwayContext>;
  date: Scalars['String'];
  form?: Maybe<Form>;
  /** Form display mode can either be conversational (1 question at a time) or regular (all questions at once). Only used in hosted pages for now. */
  form_display_mode?: Maybe<FormDisplayMode>;
  /** Url for icon, only used by extensions custom actions */
  icon_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indirect_object?: Maybe<ActivityObject>;
  isUserActivity: Scalars['Boolean'];
  label?: Maybe<ActivityLabel>;
  metadata?: Maybe<Scalars['JSON']>;
  object: ActivityObject;
  public?: Maybe<Scalars['Boolean']>;
  reference_id: Scalars['String'];
  resolution?: Maybe<ActivityResolution>;
  session_id?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<Array<ActivityObject>>;
  status: ActivityStatus;
  stream_id: Scalars['String'];
  sub_activities: Array<SubActivity>;
  subject: ActivitySubject;
  track?: Maybe<ActivityTrack>;
};

export enum ActivityAction {
  Activate = 'ACTIVATE',
  Added = 'ADDED',
  Assigned = 'ASSIGNED',
  Complete = 'COMPLETE',
  Computed = 'COMPUTED',
  Delegated = 'DELEGATED',
  Deliver = 'DELIVER',
  Discarded = 'DISCARDED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FailedToSend = 'FAILED_TO_SEND',
  Generated = 'GENERATED',
  IsWaitingOn = 'IS_WAITING_ON',
  Postponed = 'POSTPONED',
  Processed = 'PROCESSED',
  Read = 'READ',
  Remind = 'REMIND',
  Reported = 'REPORTED',
  Scheduled = 'SCHEDULED',
  Send = 'SEND',
  Skipped = 'SKIPPED',
  Stopped = 'STOPPED',
  Submitted = 'SUBMITTED'
}

export type ActivityLabel = {
  __typename?: 'ActivityLabel';
  color: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type ActivityMetadata = {
  __typename?: 'ActivityMetadata';
  stakeholders?: Maybe<Array<ActivityObject>>;
};

export type ActivityObject = {
  __typename?: 'ActivityObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  preferred_language?: Maybe<Scalars['String']>;
  type: ActivityObjectType;
};

export enum ActivityObjectType {
  Action = 'ACTION',
  ApiCall = 'API_CALL',
  Calculation = 'CALCULATION',
  Checklist = 'CHECKLIST',
  ClinicalNote = 'CLINICAL_NOTE',
  EmrReport = 'EMR_REPORT',
  EmrRequest = 'EMR_REQUEST',
  EvaluatedRule = 'EVALUATED_RULE',
  Form = 'FORM',
  Message = 'MESSAGE',
  Pathway = 'PATHWAY',
  Patient = 'PATIENT',
  Plugin = 'PLUGIN',
  PluginAction = 'PLUGIN_ACTION',
  Reminder = 'REMINDER',
  Stakeholder = 'STAKEHOLDER',
  Step = 'STEP',
  Track = 'TRACK',
  User = 'USER'
}

export enum ActivityResolution {
  Expired = 'EXPIRED',
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum ActivityStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failed = 'FAILED'
}

export type ActivitySubject = {
  __typename?: 'ActivitySubject';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: ActivitySubjectType;
};

export enum ActivitySubjectType {
  ApiCall = 'API_CALL',
  Awell = 'AWELL',
  Plugin = 'PLUGIN',
  Stakeholder = 'STAKEHOLDER',
  User = 'USER'
}

export type ActivityTrack = {
  __typename?: 'ActivityTrack';
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AddIdentifierToPatientInput = {
  identifier: IdentifierInput;
  patient_id: Scalars['String'];
};

export type AddIdentifierToPatientPayload = Payload & {
  __typename?: 'AddIdentifierToPatientPayload';
  code: Scalars['String'];
  patient?: Maybe<User>;
  success: Scalars['Boolean'];
};

export type AddMetadataInput = {
  activity_id: Scalars['String'];
  metadata: Scalars['JSON'];
  note?: Maybe<Scalars['String']>;
};

export type AddMetadataPayload = Payload & {
  __typename?: 'AddMetadataPayload';
  activity: Activity;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AddTrackInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type AddTrackPayload = Payload & {
  __typename?: 'AddTrackPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum AllowedDatesOptions {
  All = 'ALL',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type Answer = {
  __typename?: 'Answer';
  label?: Maybe<Scalars['String']>;
  question_id: Scalars['String'];
  value: Scalars['String'];
  value_type: DataPointValueType;
};

export type AnswerInput = {
  question_id: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCall = {
  __typename?: 'ApiCall';
  created_at: Scalars['String'];
  id: Scalars['ID'];
  request: ApiCallRequest;
  responses: Array<ApiCallResponse>;
  status: ApiCallStatus;
  title: Scalars['String'];
};

export type ApiCallHeader = {
  __typename?: 'ApiCallHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCallPayload = Payload & {
  __typename?: 'ApiCallPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ApiCallRequest = {
  __typename?: 'ApiCallRequest';
  body?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  headers: Array<ApiCallHeader>;
  method: ApiCallRequestMethod;
};

export enum ApiCallRequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT'
}

export type ApiCallResponse = {
  __typename?: 'ApiCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  status: Scalars['Float'];
};

export enum ApiCallStatus {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending',
  PermanentlyFailed = 'PermanentlyFailed',
  Skipped = 'Skipped',
  Success = 'Success'
}

export type ApiCallsPayload = Payload & {
  __typename?: 'ApiCallsPayload';
  api_calls: Array<ApiCall>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ApiPathwayContext = {
  __typename?: 'ApiPathwayContext';
  id: Scalars['String'];
  pathway_definition_id: Scalars['String'];
  patient_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type AuditTrail = {
  __typename?: 'AuditTrail';
  date: Scalars['SafeDate'];
  user_email?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type BaselineDataPoint = {
  __typename?: 'BaselineDataPoint';
  definition: DataPointDefinition;
  value?: Maybe<Scalars['String']>;
};

export type BaselineInfoInput = {
  data_point_definition_id: Scalars['String'];
  value: Scalars['String'];
};

export type BaselineInfoPayload = Payload & {
  __typename?: 'BaselineInfoPayload';
  baselineDataPoints: Array<BaselineDataPoint>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum BooleanOperator {
  And = 'AND',
  Or = 'OR'
}

export type BrandingSettings = {
  __typename?: 'BrandingSettings';
  accent_color?: Maybe<Scalars['String']>;
  custom_theme?: Maybe<Scalars['String']>;
  /** Auto progress to the next question when using the conversational display mode in Hosted Pages. */
  hosted_page_auto_progress?: Maybe<Scalars['Boolean']>;
  /** Automatically save question answers locally in Hosted Pages */
  hosted_page_autosave?: Maybe<Scalars['Boolean']>;
  hosted_page_title?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
};

export type CalculationResultsPayload = Payload & {
  __typename?: 'CalculationResultsPayload';
  code: Scalars['String'];
  result: Array<SingleCalculationResult>;
  success: Scalars['Boolean'];
};

export type CancelScheduledTracksInput = {
  ids: Array<Scalars['String']>;
};

export type CancelScheduledTracksPayload = Payload & {
  __typename?: 'CancelScheduledTracksPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  unscheduled_ids: Array<Scalars['String']>;
};

export type Checklist = {
  __typename?: 'Checklist';
  items: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type ChecklistPayload = Payload & {
  __typename?: 'ChecklistPayload';
  checklist?: Maybe<Checklist>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ChoiceRangeConfig = {
  __typename?: 'ChoiceRangeConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type ClinicalNotePayload = Payload & {
  __typename?: 'ClinicalNotePayload';
  clinical_note: GeneratedClinicalNote;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CompleteExtensionActivityInput = {
  activity_id: Scalars['String'];
  data_points: Array<ExtensionDataPointInput>;
};

export type CompleteExtensionActivityPayload = Payload & {
  __typename?: 'CompleteExtensionActivityPayload';
  activity: Activity;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['ID'];
  operand?: Maybe<Operand>;
  operator?: Maybe<ConditionOperator>;
  reference?: Maybe<Scalars['String']>;
  reference_key?: Maybe<Scalars['String']>;
};

export enum ConditionOperandType {
  Boolean = 'BOOLEAN',
  DataPoint = 'DATA_POINT',
  DataSource = 'DATA_SOURCE',
  Number = 'NUMBER',
  NumbersArray = 'NUMBERS_ARRAY',
  String = 'STRING',
  StringsArray = 'STRINGS_ARRAY'
}

export enum ConditionOperator {
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  IsAnyOf = 'IS_ANY_OF',
  IsEmpty = 'IS_EMPTY',
  IsEqualTo = 'IS_EQUAL_TO',
  IsGreaterThan = 'IS_GREATER_THAN',
  IsGreaterThanOrEqualTo = 'IS_GREATER_THAN_OR_EQUAL_TO',
  IsInRange = 'IS_IN_RANGE',
  IsLessThan = 'IS_LESS_THAN',
  IsLessThanOrEqualTo = 'IS_LESS_THAN_OR_EQUAL_TO',
  IsLessThanXDaysAgo = 'IS_LESS_THAN_X_DAYS_AGO',
  IsMoreThanXDaysAgo = 'IS_MORE_THAN_X_DAYS_AGO',
  IsNoneOf = 'IS_NONE_OF',
  IsNotEmpty = 'IS_NOT_EMPTY',
  IsNotEqualTo = 'IS_NOT_EQUAL_TO',
  IsNotTrue = 'IS_NOT_TRUE',
  IsToday = 'IS_TODAY',
  IsTrue = 'IS_TRUE'
}

export type CreatePatientInput = {
  address?: Maybe<AddressInput>;
  birth_date?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  identifier?: Maybe<Array<IdentifierInput>>;
  last_name?: Maybe<Scalars['String']>;
  /** Must be in valid E164 telephone number format */
  mobile_phone?: Maybe<Scalars['String']>;
  national_registry_number?: Maybe<Scalars['String']>;
  patient_code?: Maybe<Scalars['String']>;
  /** Must be in valid E164 telephone number format */
  phone?: Maybe<Scalars['String']>;
  /** ISO 639-1 shortcode */
  preferred_language?: Maybe<Scalars['String']>;
  /** Sex code as defined by ISO standard IEC_5218, 0 - NOT_KNOWN, 1 - MALE, 2 - FEMALE */
  sex?: Maybe<Sex>;
};

export type CreatePatientPayload = Payload & {
  __typename?: 'CreatePatientPayload';
  code: Scalars['String'];
  patient?: Maybe<User>;
  success: Scalars['Boolean'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  id: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  tenant: Tenant;
  tenant_id: Scalars['String'];
};

export type CurrentUserPayload = Payload & {
  __typename?: 'CurrentUserPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  user: CurrentUser;
};

export type DataPoint = {
  __typename?: 'DataPoint';
  activity_id?: Maybe<Scalars['String']>;
  data_point_definition_id: Scalars['String'];
  data_set_id: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  serialized_value?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataPointDefinition = {
  __typename?: 'DataPointDefinition';
  category: DataPointSourceType;
  id: Scalars['ID'];
  key: Scalars['String'];
  /** Additional context on data point */
  metadata?: Maybe<Array<DataPointMetaDataItem>>;
  optional?: Maybe<Scalars['Boolean']>;
  /** Personally identifiable information */
  pii?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValue>>;
  range?: Maybe<Range>;
  source_definition_id: Scalars['String'];
  title: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataPointInput = {
  data_point_definition_id: Scalars['String'];
  value: Scalars['String'];
};

export type DataPointMetaDataItem = {
  __typename?: 'DataPointMetaDataItem';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DataPointPayload = PaginationAndSortingPayload & {
  __typename?: 'DataPointPayload';
  code: Scalars['String'];
  dataPoints: Array<DataPoint>;
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type DataPointPossibleValue = {
  __typename?: 'DataPointPossibleValue';
  label?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export enum DataPointSourceType {
  ApiCall = 'API_CALL',
  ApiCallStatus = 'API_CALL_STATUS',
  Calculation = 'CALCULATION',
  DataPoint = 'DATA_POINT',
  ExtensionAction = 'EXTENSION_ACTION',
  ExtensionWebhook = 'EXTENSION_WEBHOOK',
  Form = 'FORM',
  Pathway = 'PATHWAY',
  PatientIdentifier = 'PATIENT_IDENTIFIER',
  PatientProfile = 'PATIENT_PROFILE',
  Step = 'STEP',
  Track = 'TRACK'
}

export enum DataPointValueType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Json = 'JSON',
  Number = 'NUMBER',
  NumbersArray = 'NUMBERS_ARRAY',
  String = 'STRING',
  StringsArray = 'STRINGS_ARRAY',
  Telephone = 'TELEPHONE'
}

export type DateConfig = {
  __typename?: 'DateConfig';
  allowed_dates?: Maybe<AllowedDatesOptions>;
  include_date_of_response?: Maybe<Scalars['Boolean']>;
};

export type DateFilter = {
  gte?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
};

export type DeletePathwayInput = {
  pathway_id: Scalars['String'];
};

export type DeletePatientInput = {
  patient_id: Scalars['String'];
};

export type EmrRequest = {
  __typename?: 'EMRRequest';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Element = {
  __typename?: 'Element';
  activity_type?: Maybe<ActionType>;
  context: PathwayContext;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<ActivityLabel>;
  name: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  stakeholders: Array<ElementStakeholder>;
  start_date: Scalars['String'];
  status: ElementStatus;
  type: ElementType;
};

export type ElementStakeholder = {
  __typename?: 'ElementStakeholder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum ElementStatus {
  Active = 'ACTIVE',
  Discarded = 'DISCARDED',
  Done = 'DONE',
  Postponed = 'POSTPONED',
  Scheduled = 'SCHEDULED',
  Stopped = 'STOPPED'
}

export enum ElementType {
  Action = 'ACTION',
  Pathway = 'PATHWAY',
  Step = 'STEP',
  Track = 'TRACK',
  Trigger = 'TRIGGER'
}

export type ElementsPayload = Payload & {
  __typename?: 'ElementsPayload';
  code: Scalars['String'];
  elements: Array<Element>;
  success: Scalars['Boolean'];
};

export type EmptyPayload = Payload & {
  __typename?: 'EmptyPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type EmrReport = {
  __typename?: 'EmrReport';
  id: Scalars['ID'];
  message_html: Scalars['String'];
  metadata?: Maybe<Array<EmrReportMetadataField>>;
};

export type EmrReportMetadataField = {
  __typename?: 'EmrReportMetadataField';
  id: Scalars['ID'];
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type EmrReportPayload = Payload & {
  __typename?: 'EmrReportPayload';
  code: Scalars['String'];
  report?: Maybe<EmrReport>;
  success: Scalars['Boolean'];
};

export type EvaluateFormRulesInput = {
  answers: Array<AnswerInput>;
  form_id: Scalars['String'];
};

export type EvaluateFormRulesPayload = Payload & {
  __typename?: 'EvaluateFormRulesPayload';
  code: Scalars['String'];
  results: Array<QuestionRuleResult>;
  success: Scalars['Boolean'];
};

export type ExclusiveOptionConfig = {
  __typename?: 'ExclusiveOptionConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  option_id?: Maybe<Scalars['String']>;
};

export type ExtensionActionField = {
  __typename?: 'ExtensionActionField';
  id: Scalars['ID'];
  label: Scalars['String'];
  type: ExtensionActionFieldType;
  value: Scalars['String'];
};

export enum ExtensionActionFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Html = 'HTML',
  Json = 'JSON',
  Numeric = 'NUMERIC',
  NumericArray = 'NUMERIC_ARRAY',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
  Text = 'TEXT'
}

export type ExtensionActivityRecord = {
  __typename?: 'ExtensionActivityRecord';
  activity_id: Scalars['String'];
  data_points: Array<ExtensionDataPoint>;
  date: Scalars['String'];
  fields: Array<ExtensionActionField>;
  id: Scalars['ID'];
  pathway_id: Scalars['String'];
  plugin_action_key: Scalars['String'];
  plugin_key: Scalars['String'];
  settings?: Maybe<Array<PluginActionSettingsProperty>>;
};

export type ExtensionActivityRecordPayload = Payload & {
  __typename?: 'ExtensionActivityRecordPayload';
  code: Scalars['String'];
  record: ExtensionActivityRecord;
  success: Scalars['Boolean'];
};

export type ExtensionDataPoint = {
  __typename?: 'ExtensionDataPoint';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ExtensionDataPointInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type FilterActivitiesParams = {
  action?: Maybe<StringArrayFilter>;
  activity_status?: Maybe<StringArrayFilter>;
  activity_type?: Maybe<StringArrayFilter>;
  pathway_definition_id?: Maybe<StringArrayFilter>;
  pathway_status?: Maybe<StringArrayFilter>;
  patient_id?: Maybe<TextFilterEquals>;
  stakeholders?: Maybe<StringArrayFilter>;
};

export type FilterPathwayDataPointDefinitionsParams = {
  category?: Maybe<StringArrayFilter>;
  value_type?: Maybe<StringArrayFilter>;
};

export type FilterPathways = {
  pathway_definition_id?: Maybe<IdFilter>;
  patient_id?: Maybe<StringArrayFilter>;
  release_id?: Maybe<StringArrayFilter>;
  start_date?: Maybe<DateFilter>;
  status?: Maybe<StringArrayFilter>;
  version?: Maybe<NumberArrayFilter>;
};

export type FilterPatientPathways = {
  status: StringArrayFilter;
};

export type FilterPatients = {
  name?: Maybe<TextFilter>;
  national_registry_number?: Maybe<TextFilterEquals>;
  patient_code?: Maybe<TextFilterEquals>;
  profile_id?: Maybe<StringArrayFilter>;
  search?: Maybe<TextFilterContains>;
};

export type Form = {
  __typename?: 'Form';
  definition_id: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  previous_answers?: Maybe<Array<PreviousAnswers>>;
  questions: Array<Question>;
  release_id: Scalars['String'];
  title: Scalars['String'];
  trademark?: Maybe<Scalars['String']>;
};


export type FormPrevious_AnswersArgs = {
  pathway_id: Scalars['String'];
};

export enum FormDisplayMode {
  Conversational = 'CONVERSATIONAL',
  Regular = 'REGULAR'
}

export type FormPayload = Payload & {
  __typename?: 'FormPayload';
  code: Scalars['String'];
  form?: Maybe<Form>;
  success: Scalars['Boolean'];
};

export type FormResponse = {
  __typename?: 'FormResponse';
  answers: Array<Answer>;
};

export type FormResponsePayload = Payload & {
  __typename?: 'FormResponsePayload';
  code: Scalars['String'];
  response: FormResponse;
  success: Scalars['Boolean'];
};

export type FormsPayload = Payload & {
  __typename?: 'FormsPayload';
  code: Scalars['String'];
  forms?: Maybe<Array<Form>>;
  success: Scalars['Boolean'];
};

export type GenerateRetoolEmbedUrlPayload = Payload & {
  __typename?: 'GenerateRetoolEmbedUrlPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type GeneratedClinicalNote = {
  __typename?: 'GeneratedClinicalNote';
  context: Array<GeneratedClinicalNoteContextField>;
  id: Scalars['ID'];
  narratives: Array<GeneratedClinicalNoteNarrative>;
};

export type GeneratedClinicalNoteContextField = {
  __typename?: 'GeneratedClinicalNoteContextField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GeneratedClinicalNoteNarrative = {
  __typename?: 'GeneratedClinicalNoteNarrative';
  body: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  title: Scalars['String'];
};

export type HostedPagesLink = {
  __typename?: 'HostedPagesLink';
  id: Scalars['ID'];
  pathway_id: Scalars['String'];
  stakeholder_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type HostedPagesLinkPayload = Payload & {
  __typename?: 'HostedPagesLinkPayload';
  code: Scalars['String'];
  /** The hosted pages link for the stakeholder. If there is no activity for the stakeholder in the care flow, this link will be null. */
  hosted_pages_link?: Maybe<HostedPagesLink>;
  success: Scalars['Boolean'];
};

export type HostedSession = {
  __typename?: 'HostedSession';
  cancel_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pathway_id: Scalars['String'];
  stakeholder: HostedSessionStakeholder;
  status: HostedSessionStatus;
  success_url?: Maybe<Scalars['String']>;
};

export type HostedSessionActivitiesPayload = Payload & {
  __typename?: 'HostedSessionActivitiesPayload';
  activities: Array<Activity>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type HostedSessionPayload = Payload & {
  __typename?: 'HostedSessionPayload';
  branding?: Maybe<BrandingSettings>;
  code: Scalars['String'];
  metadata?: Maybe<SessionMetadata>;
  session: HostedSession;
  success: Scalars['Boolean'];
};

export type HostedSessionStakeholder = {
  __typename?: 'HostedSessionStakeholder';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: HostedSessionStakeholderType;
};

export enum HostedSessionStakeholderType {
  Patient = 'PATIENT',
  Stakeholder = 'STAKEHOLDER'
}

export enum HostedSessionStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED'
}

export type IdFilter = {
  eq?: Maybe<Scalars['String']>;
};

export type Identifier = {
  __typename?: 'Identifier';
  system: Scalars['String'];
  value: Scalars['String'];
};

export type IdentifierInput = {
  system: Scalars['String'];
  value: Scalars['String'];
};

export type IdentifierSystem = {
  __typename?: 'IdentifierSystem';
  display_name: Scalars['String'];
  name: Scalars['String'];
  system: Scalars['String'];
};

export type IdentityVerificationPayload = Payload & {
  __typename?: 'IdentityVerificationPayload';
  code: Scalars['String'];
  is_verified: Scalars['Boolean'];
  success: Scalars['Boolean'];
};


export type MarkMessageAsReadInput = {
  activity_id: Scalars['String'];
};

export type MarkMessageAsReadPayload = Payload & {
  __typename?: 'MarkMessageAsReadPayload';
  activity: Activity;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<MessageAttachment>>;
  body: Scalars['String'];
  format: MessageFormat;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export enum MessageAttachmentType {
  File = 'FILE',
  Link = 'LINK',
  Video = 'VIDEO'
}

export enum MessageFormat {
  Html = 'HTML',
  Slate = 'SLATE'
}

export type MessagePayload = Payload & {
  __typename?: 'MessagePayload';
  code: Scalars['String'];
  message?: Maybe<Message>;
  success: Scalars['Boolean'];
};

export type MultipleSelectConfig = {
  __typename?: 'MultipleSelectConfig';
  exclusive_option?: Maybe<ExclusiveOptionConfig>;
  range?: Maybe<ChoiceRangeConfig>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActivityMetadata: AddMetadataPayload;
  addIdentifierToPatient: AddIdentifierToPatientPayload;
  addTrack: AddTrackPayload;
  completeExtensionActivity: CompleteExtensionActivityPayload;
  createPatient: CreatePatientPayload;
  deletePathway: EmptyPayload;
  deletePatient: EmptyPayload;
  evaluateFormRules: EvaluateFormRulesPayload;
  markMessageAsRead: MarkMessageAsReadPayload;
  /** Retrieve patient demographics from an external system */
  requestPatientDemographics: PatientDemographicsPayload;
  retryActivity: EmptyPayload;
  retryAllApiCalls: EmptyPayload;
  retryAllFailedApiCalls: EmptyPayload;
  retryAllFailedWebhookCalls: EmptyPayload;
  retryAllFailedWebhookCallsForPathwayDefinition: EmptyPayload;
  retryAllWebhookCalls: EmptyPayload;
  retryApiCall: RetryApiCallPayload;
  retryPushToEmr: EmptyPayload;
  retryWebhookCall: RetryWebhookCallPayload;
  /** @deprecated We will be deactivating this endpoint in the future. */
  saveBaselineInfo: EmptyPayload;
  scheduleTrack: ScheduleTrackPayload;
  setPageTitle?: Maybe<Scalars['Boolean']>;
  startHostedActivitySession: StartHostedActivitySessionPayload;
  startHostedActivitySessionViaHostedPagesLink: StartHostedActivitySessionPayload;
  /** Start a hosted pathway session for a patient uniquely identified by patient_id or patient_identifier. If neither patient_id or patient_identifier is provided, a new anonymous patient will be created. */
  startHostedPathwaySession: StartHostedPathwaySessionPayload;
  startHostedPathwaySessionFromLink: StartHostedPathwaySessionFromLinkPayload;
  startPathway: StartPathwayPayload;
  startPathwayWithPatientIdentifier: StartPathwayWithPatientIdentifierPayload;
  stopPathway: EmptyPayload;
  stopTrack: StopTrackPayload;
  submitChecklist: SubmitChecklistPayload;
  submitFormResponse: SubmitFormResponsePayload;
  unscheduleTracks: CancelScheduledTracksPayload;
  updateBaselineInfo: EmptyPayload;
  updateEmrReportStatus: UpdateEmrReportStatusPayload;
  updatePatient: UpdatePatientPayload;
  /** Update which patient was created after import request for logging purposes */
  updatePatientDemographicsQuery: UpdatePatientDemographicsQueryPayload;
  updatePatientLanguage: UpdatePatientLanguagePayload;
  verify_identity: IdentityVerificationPayload;
};


export type MutationAddActivityMetadataArgs = {
  input: AddMetadataInput;
};


export type MutationAddIdentifierToPatientArgs = {
  input: AddIdentifierToPatientInput;
};


export type MutationAddTrackArgs = {
  input: AddTrackInput;
};


export type MutationCompleteExtensionActivityArgs = {
  input: CompleteExtensionActivityInput;
};


export type MutationCreatePatientArgs = {
  input?: Maybe<CreatePatientInput>;
};


export type MutationDeletePathwayArgs = {
  input: DeletePathwayInput;
};


export type MutationDeletePatientArgs = {
  input: DeletePatientInput;
};


export type MutationEvaluateFormRulesArgs = {
  input: EvaluateFormRulesInput;
};


export type MutationMarkMessageAsReadArgs = {
  input: MarkMessageAsReadInput;
};


export type MutationRequestPatientDemographicsArgs = {
  input: PatientDemographicsInput;
};


export type MutationRetryActivityArgs = {
  input: RetryActivityInput;
};


export type MutationRetryAllApiCallsArgs = {
  input: RetryAllApiCallsInput;
};


export type MutationRetryAllFailedApiCallsArgs = {
  input: RetryAllFailedApiCallsInput;
};


export type MutationRetryAllFailedWebhookCallsArgs = {
  input: RetryAllFailedWebhookCallsInput;
};


export type MutationRetryAllFailedWebhookCallsForPathwayDefinitionArgs = {
  input: RetryAllFailedWebhookCallsForPathwayDefinitionInput;
};


export type MutationRetryAllWebhookCallsArgs = {
  input: RetryAllWebhookCallsInput;
};


export type MutationRetryApiCallArgs = {
  input: RetryApiCallInput;
};


export type MutationRetryPushToEmrArgs = {
  input: RetryPushToEmrInput;
};


export type MutationRetryWebhookCallArgs = {
  input: RetryWebhookCallInput;
};


export type MutationSaveBaselineInfoArgs = {
  input: SaveBaselineInfoInput;
};


export type MutationScheduleTrackArgs = {
  input: ScheduleTrackInput;
};


export type MutationSetPageTitleArgs = {
  title?: Maybe<Scalars['String']>;
};


export type MutationStartHostedActivitySessionArgs = {
  input: StartHostedActivitySessionInput;
};


export type MutationStartHostedActivitySessionViaHostedPagesLinkArgs = {
  input: StartHostedActivitySessionViaHostedPagesLinkInput;
};


export type MutationStartHostedPathwaySessionArgs = {
  input: StartHostedPathwaySessionInput;
};


export type MutationStartHostedPathwaySessionFromLinkArgs = {
  input: StartHostedPathwaySessionFromLinkInput;
};


export type MutationStartPathwayArgs = {
  input: StartPathwayInput;
};


export type MutationStartPathwayWithPatientIdentifierArgs = {
  input: StartPathwayWithPatientIdentifierInput;
};


export type MutationStopPathwayArgs = {
  input: StopPathwayInput;
};


export type MutationStopTrackArgs = {
  input: StopTrackInput;
};


export type MutationSubmitChecklistArgs = {
  input: SubmitChecklistInput;
};


export type MutationSubmitFormResponseArgs = {
  input: SubmitFormResponseInput;
};


export type MutationUnscheduleTracksArgs = {
  input: CancelScheduledTracksInput;
};


export type MutationUpdateBaselineInfoArgs = {
  input: UpdateBaselineInfoInput;
};


export type MutationUpdateEmrReportStatusArgs = {
  input: UpdateEmrReportStatusInput;
};


export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};


export type MutationUpdatePatientDemographicsQueryArgs = {
  input: UpdatePatientDemographicsQueryInput;
};


export type MutationUpdatePatientLanguageArgs = {
  input: UpdatePatientLanguageInput;
};


export type MutationVerify_IdentityArgs = {
  input: VerifyIdentityInput;
};

export type NumberArrayFilter = {
  in?: Maybe<Array<Scalars['Float']>>;
};

export type NumberConfig = {
  __typename?: 'NumberConfig';
  range?: Maybe<RangeConfig>;
};

export type Operand = {
  __typename?: 'Operand';
  type: ConditionOperandType;
  value: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['Float'];
  value_string: Scalars['String'];
};

export type OrchestrationFact = {
  __typename?: 'OrchestrationFact';
  content: Array<Scalars['String']>;
  date: Scalars['String'];
  level: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type OrchestrationFactsPayload = PaginationAndSortingPayload & {
  __typename?: 'OrchestrationFactsPayload';
  code: Scalars['String'];
  facts: Array<OrchestrationFact>;
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type OrchestrationFactsPromptPayload = Payload & {
  __typename?: 'OrchestrationFactsPromptPayload';
  code: Scalars['String'];
  response: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PaginationAndSortingPayload = {
  code: Scalars['String'];
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  count?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type PaginationParams = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};

/** A care flow, also including any activities or swimlanes. Otherwise, it should be almost identical to the PathwaySummary, which is returned when retrieving a list of care flows. */
export type Pathway = {
  __typename?: 'Pathway';
  /**
   * Deprecated. Please use latestActivities.
   * @deprecated use latestActivities instead. Limited to most recent 1000 activities
   */
  activities?: Maybe<Array<Activity>>;
  complete_date?: Maybe<Scalars['SafeDate']>;
  id: Scalars['ID'];
  /** Activities, sorted by date in descending order. For larger care flows, only the most recent 1000 activities are included. To see a complete list of activities, please use the `activity` query and appropriate filters. */
  latestActivities: Array<Activity>;
  pathway_definition_id: Scalars['String'];
  patient: User;
  patient_id: Scalars['String'];
  release_id: Scalars['String'];
  start_date?: Maybe<Scalars['SafeDate']>;
  status: PathwayStatus;
  status_explanation?: Maybe<Scalars['String']>;
  stop_date?: Maybe<Scalars['SafeDate']>;
  title: Scalars['String'];
  tracks: Array<Track>;
  version?: Maybe<Scalars['Float']>;
};

export type PathwayContext = {
  __typename?: 'PathwayContext';
  action_id?: Maybe<Scalars['String']>;
  instance_id: Scalars['String'];
  pathway_id: Scalars['String'];
  step_id?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['String']>;
};

export type PathwayDataPointDefinitionsPayload = Payload & {
  __typename?: 'PathwayDataPointDefinitionsPayload';
  code: Scalars['String'];
  data_point_definitions: Array<DataPointDefinition>;
  success: Scalars['Boolean'];
};

export type PathwayDefinitionDetails = {
  __typename?: 'PathwayDefinitionDetails';
  active_careflows?: Maybe<Scalars['Float']>;
  completed_careflows?: Maybe<Scalars['Float']>;
  stopped_careflows?: Maybe<Scalars['Float']>;
  total_careflows?: Maybe<Scalars['Float']>;
  total_patients?: Maybe<Scalars['Float']>;
};

export type PathwayFactsFilters = {
  date?: Maybe<DateFilter>;
  keyword?: Maybe<Scalars['String']>;
  pathway_id: Scalars['String'];
};

export type PathwayPayload = Payload & {
  __typename?: 'PathwayPayload';
  code: Scalars['String'];
  pathway?: Maybe<Pathway>;
  success: Scalars['Boolean'];
};

export enum PathwayStatus {
  Active = 'active',
  Completed = 'completed',
  MissingBaselineInfo = 'missing_baseline_info',
  Starting = 'starting',
  Stopped = 'stopped'
}

/** A summary of a pathway instance, excluding any activities. Useful for list views. */
export type PathwaySummary = {
  __typename?: 'PathwaySummary';
  complete_date?: Maybe<Scalars['SafeDate']>;
  id: Scalars['ID'];
  pathway_definition_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['SafeDate']>;
  status: PathwayStatus;
  status_explanation?: Maybe<Scalars['String']>;
  stop_date?: Maybe<Scalars['SafeDate']>;
  title: Scalars['String'];
  version?: Maybe<Scalars['Float']>;
};

export type PathwaysPayload = PaginationAndSortingPayload & {
  __typename?: 'PathwaysPayload';
  code: Scalars['String'];
  pagination?: Maybe<PaginationOutput>;
  pathways: Array<PathwaySummary>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type PatientDemographicsInput = {
  patient_identifier: Scalars['String'];
};

export type PatientDemographicsPayload = Payload & {
  __typename?: 'PatientDemographicsPayload';
  code: Scalars['String'];
  entry?: Maybe<Array<UserProfile>>;
  query_id: Scalars['String'];
  status: Scalars['String'];
  success: Scalars['Boolean'];
  total?: Maybe<Scalars['Float']>;
};

export type PatientDemographicsQueryConfigurationPayload = {
  __typename?: 'PatientDemographicsQueryConfigurationPayload';
  input_box_text?: Maybe<Scalars['String']>;
  is_enabled: Scalars['Boolean'];
};

export type PatientPathway = {
  __typename?: 'PatientPathway';
  active_activities?: Maybe<Scalars['Float']>;
  baseline_info?: Maybe<Array<BaselineDataPoint>>;
  complete_date?: Maybe<Scalars['String']>;
  failed_activities?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  latest_activity_date?: Maybe<Scalars['String']>;
  latest_activity_title?: Maybe<Scalars['String']>;
  latest_activity_type?: Maybe<Scalars['String']>;
  pathway_definition_id: Scalars['String'];
  release_id: Scalars['String'];
  status: PathwayStatus;
  status_explanation?: Maybe<Scalars['String']>;
  stop_date?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  total_activities?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

export type PatientPathwaysPayload = Payload & {
  __typename?: 'PatientPathwaysPayload';
  code: Scalars['String'];
  patientPathways: Array<PatientPathway>;
  success: Scalars['Boolean'];
};

export type PatientPayload = Payload & {
  __typename?: 'PatientPayload';
  code: Scalars['String'];
  patient?: Maybe<User>;
  success: Scalars['Boolean'];
};

export type PatientProfileInput = {
  address?: Maybe<AddressInput>;
  birth_date?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  identifier?: Maybe<Array<IdentifierInput>>;
  last_name?: Maybe<Scalars['String']>;
  /** Must be in valid E164 telephone number format */
  mobile_phone?: Maybe<Scalars['String']>;
  national_registry_number?: Maybe<Scalars['String']>;
  patient_code?: Maybe<Scalars['String']>;
  /** Must be in valid E164 telephone number format */
  phone?: Maybe<Scalars['String']>;
  /** ISO 639-1 shortcode */
  preferred_language?: Maybe<Scalars['String']>;
  /** Sex code as defined by ISO standard IEC_5218, 0 - NOT_KNOWN, 1 - MALE, 2 - FEMALE */
  sex?: Maybe<Sex>;
};

export type PatientsPayload = PaginationAndSortingPayload & {
  __typename?: 'PatientsPayload';
  code: Scalars['String'];
  pagination?: Maybe<PaginationOutput>;
  patients: Array<User>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type Payload = {
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PhoneConfig = {
  __typename?: 'PhoneConfig';
  available_countries?: Maybe<Array<Scalars['String']>>;
  default_country?: Maybe<Scalars['String']>;
};

export type PluginActionSettingsProperty = {
  __typename?: 'PluginActionSettingsProperty';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type PreviousAnswers = {
  __typename?: 'PreviousAnswers';
  activity_id: Scalars['ID'];
  answers: Array<Answer>;
  date: Scalars['String'];
};

export type PublishedPathwayDefinition = {
  __typename?: 'PublishedPathwayDefinition';
  active_activities?: Maybe<Scalars['Float']>;
  /** Details about the latest pathway definition */
  all?: Maybe<PathwayDefinitionDetails>;
  cancelled_activities?: Maybe<Scalars['Float']>;
  created?: Maybe<AuditTrail>;
  /**
   * Starting/baseline data point definitions for the pathway
   * @deprecated Use data_point_definitions instead
   */
  dataPointDefinitions: Array<DataPointDefinition>;
  /** Starting/baseline data point definitions for the pathway */
  data_point_definitions?: Maybe<Array<DataPointDefinition>>;
  failed_activities?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  /** Details about all pathway definitions */
  latest?: Maybe<PathwayDefinitionDetails>;
  patients_with_pending_activities?: Maybe<Scalars['Float']>;
  release_date?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['String']>;
  stakeholders_with_pending_activities_list?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  total_activities?: Maybe<Scalars['Float']>;
  total_patients?: Maybe<Scalars['Float']>;
  total_stakeholders?: Maybe<Scalars['Float']>;
  /** Tracks for the pathway */
  track_definitions?: Maybe<Array<Track>>;
  version?: Maybe<Scalars['Float']>;
};

export type PublishedPathwayDefinitionsPayload = PaginationAndSortingPayload & {
  __typename?: 'PublishedPathwayDefinitionsPayload';
  code: Scalars['String'];
  pagination?: Maybe<PaginationOutput>;
  publishedPathwayDefinitions: Array<PublishedPathwayDefinition>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  activities: ActivitiesPayload;
  adHocTracksByPathway: TracksPayload;
  adHocTracksByRelease: TracksPayload;
  apiCall: ApiCallPayload;
  apiCalls: ApiCallsPayload;
  baselineInfo: BaselineInfoPayload;
  calculationAction: ActionPayload;
  calculationResults: CalculationResultsPayload;
  checklist: ChecklistPayload;
  clinicalNote: ClinicalNotePayload;
  emrReport: EmrReportPayload;
  extensionActivityRecord: ExtensionActivityRecordPayload;
  filterStakeholders: StakeholdersPayload;
  form: FormPayload;
  formResponse: FormResponsePayload;
  forms: FormsPayload;
  generateRetoolEmbedUrl: GenerateRetoolEmbedUrlPayload;
  getOrchestrationFactsFromPrompt: OrchestrationFactsPromptPayload;
  getStatusForPublishedPathwayDefinitions: PublishedPathwayDefinitionsPayload;
  hostedPagesLink: HostedPagesLinkPayload;
  hostedSession: HostedSessionPayload;
  hostedSessionActivities: HostedSessionActivitiesPayload;
  message: MessagePayload;
  myActivities: ActivitiesPayload;
  /** @deprecated Use the `pathways` query instead. */
  myPathways: PathwaysPayload;
  myPendingActivities: ActivitiesPayload;
  pageTitle?: Maybe<Scalars['String']>;
  pathway: PathwayPayload;
  pathwayActivities: ActivitiesPayload;
  pathwayDataPointDefinitions: PathwayDataPointDefinitionsPayload;
  pathwayDataPoints: DataPointPayload;
  pathwayElements: ElementsPayload;
  pathwayFacts: OrchestrationFactsPayload;
  pathwayLayout?: Maybe<PathwayLayout>;
  pathwayStepActivities: ActivitiesPayload;
  pathways: PathwaysPayload;
  patient: PatientPayload;
  patientByIdentifier: PatientPayload;
  patientDemographicsQueryConfiguration: PatientDemographicsQueryConfigurationPayload;
  patientPathways: PatientPathwaysPayload;
  patients: PatientsPayload;
  publishedPathwayDefinitions: PublishedPathwayDefinitionsPayload;
  scheduledSteps: ScheduledStepsPayload;
  scheduledTracksForPathway: ScheduledTracksPayload;
  searchPatientsByNationalRegistryNumber: SearchPatientsPayload;
  searchPatientsByPatientCode: SearchPatientsPayload;
  stakeholdersByDefinitionIds: StakeholdersPayload;
  stakeholdersByPathwayDefinitionIds: StakeholdersPayload;
  stakeholdersByReleaseIds: StakeholdersPayload;
  webhookCall: WebhookCallPayload;
  webhookCalls: WebhookCallsPayload;
  webhookCallsForPathwayDefinition: WebhookCallsPayload;
  webhookCallsForTenant: WebhookCallsPayload;
  whoami: CurrentUserPayload;
};


export type QueryActivitiesArgs = {
  filters?: Maybe<FilterActivitiesParams>;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
};


export type QueryAdHocTracksByPathwayArgs = {
  pathway_id: Scalars['String'];
};


export type QueryAdHocTracksByReleaseArgs = {
  release_id: Scalars['String'];
};


export type QueryApiCallArgs = {
  id: Scalars['String'];
};


export type QueryApiCallsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryBaselineInfoArgs = {
  pathway_id: Scalars['String'];
};


export type QueryCalculationActionArgs = {
  id: Scalars['String'];
};


export type QueryCalculationResultsArgs = {
  activity_id: Scalars['String'];
  pathway_id: Scalars['String'];
};


export type QueryChecklistArgs = {
  id: Scalars['String'];
};


export type QueryClinicalNoteArgs = {
  id: Scalars['String'];
};


export type QueryEmrReportArgs = {
  id: Scalars['String'];
};


export type QueryExtensionActivityRecordArgs = {
  id: Scalars['String'];
};


export type QueryFilterStakeholdersArgs = {
  pathway_definition_ids?: Maybe<Array<Scalars['String']>>;
  release_ids?: Maybe<Array<Scalars['String']>>;
  stakeholder_definition_ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryFormArgs = {
  id: Scalars['String'];
};


export type QueryFormResponseArgs = {
  activity_id: Scalars['String'];
  pathway_id: Scalars['String'];
};


export type QueryFormsArgs = {
  pathway_definition_id: Scalars['String'];
  release_id?: Maybe<Scalars['String']>;
};


export type QueryGenerateRetoolEmbedUrlArgs = {
  groupIds: Array<Scalars['String']>;
  landingPageUuid: Scalars['String'];
  userInfo: UserInfoParams;
};


export type QueryGetOrchestrationFactsFromPromptArgs = {
  pathway_id: Scalars['String'];
  prompt: Scalars['String'];
};


export type QueryHostedPagesLinkArgs = {
  pathway_id: Scalars['String'];
  stakeholder_id: Scalars['String'];
};


export type QueryHostedSessionActivitiesArgs = {
  only_stakeholder_activities?: Maybe<Scalars['Boolean']>;
};


export type QueryMessageArgs = {
  id: Scalars['String'];
};


export type QueryMyActivitiesArgs = {
  pagination?: Maybe<PaginationParams>;
  pathway_id: Scalars['String'];
  sorting?: Maybe<SortingParams>;
};


export type QueryPathwayArgs = {
  id: Scalars['String'];
};


export type QueryPathwayActivitiesArgs = {
  pagination?: Maybe<PaginationParams>;
  pathway_id: Scalars['String'];
  sorting?: Maybe<SortingParams>;
};


export type QueryPathwayDataPointDefinitionsArgs = {
  filters?: Maybe<FilterPathwayDataPointDefinitionsParams>;
  pathway_definition_id?: Maybe<Scalars['String']>;
  release_id: Scalars['String'];
};


export type QueryPathwayDataPointsArgs = {
  activity_id?: Maybe<Scalars['String']>;
  data_point_definition_id?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationParams>;
  pathway_id: Scalars['String'];
  sorting?: Maybe<SortingParams>;
};


export type QueryPathwayElementsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwayFactsArgs = {
  filters: PathwayFactsFilters;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
};


export type QueryPathwayStepActivitiesArgs = {
  pathway_id: Scalars['String'];
  step_id: Scalars['String'];
};


export type QueryPathwaysArgs = {
  filters?: Maybe<FilterPathways>;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
};


export type QueryPatientArgs = {
  id: Scalars['String'];
};


export type QueryPatientByIdentifierArgs = {
  system: Scalars['String'];
  value: Scalars['String'];
};


export type QueryPatientPathwaysArgs = {
  filters?: Maybe<FilterPatientPathways>;
  patient_id: Scalars['String'];
};


export type QueryPatientsArgs = {
  filters?: Maybe<FilterPatients>;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
};


export type QueryScheduledStepsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryScheduledTracksForPathwayArgs = {
  pathway_id: Scalars['String'];
};


export type QuerySearchPatientsByNationalRegistryNumberArgs = {
  national_registry_number: Scalars['String'];
};


export type QuerySearchPatientsByPatientCodeArgs = {
  patient_code: Scalars['String'];
};


export type QueryStakeholdersByDefinitionIdsArgs = {
  stakeholder_definition_ids: Array<Scalars['String']>;
};


export type QueryStakeholdersByPathwayDefinitionIdsArgs = {
  pathway_definition_ids: Array<Scalars['String']>;
};


export type QueryStakeholdersByReleaseIdsArgs = {
  release_ids: Array<Scalars['String']>;
};


export type QueryWebhookCallArgs = {
  webhook_call_id: Scalars['String'];
};


export type QueryWebhookCallsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryWebhookCallsForPathwayDefinitionArgs = {
  pathway_definition_id: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  dataPointValueType?: Maybe<DataPointValueType>;
  definition_id: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Option>>;
  questionConfig?: Maybe<QuestionConfig>;
  questionType?: Maybe<QuestionType>;
  rule?: Maybe<Rule>;
  title: Scalars['String'];
  userQuestionType?: Maybe<UserQuestionType>;
};

export type QuestionConfig = {
  __typename?: 'QuestionConfig';
  date?: Maybe<DateConfig>;
  mandatory: Scalars['Boolean'];
  multiple_select?: Maybe<MultipleSelectConfig>;
  number?: Maybe<NumberConfig>;
  phone?: Maybe<PhoneConfig>;
  recode_enabled?: Maybe<Scalars['Boolean']>;
  slider?: Maybe<SliderConfig>;
  use_select?: Maybe<Scalars['Boolean']>;
};

export type QuestionResponseInput = {
  question_id: Scalars['String'];
  value: Scalars['String'];
};

export type QuestionRuleResult = {
  __typename?: 'QuestionRuleResult';
  question_id: Scalars['String'];
  rule_id: Scalars['String'];
  satisfied: Scalars['Boolean'];
};

export enum QuestionType {
  Input = 'INPUT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  NoInput = 'NO_INPUT'
}

export type Range = {
  __typename?: 'Range';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type RangeConfig = {
  __typename?: 'RangeConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type RetryActivityInput = {
  activity_id: Scalars['String'];
};

export type RetryAllApiCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryAllFailedApiCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryAllFailedWebhookCallsForPathwayDefinitionInput = {
  pathway_definition_id: Scalars['String'];
};

export type RetryAllFailedWebhookCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryAllWebhookCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryApiCallInput = {
  api_call_id: Scalars['String'];
};

export type RetryApiCallPayload = Payload & {
  __typename?: 'RetryApiCallPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RetryPushToEmrInput = {
  activity_id: Scalars['String'];
};

export type RetryWebhookCallInput = {
  webhook_call_id: Scalars['String'];
};

export type RetryWebhookCallPayload = Payload & {
  __typename?: 'RetryWebhookCallPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  webhook_call: WebhookCall;
};

export type Rule = {
  __typename?: 'Rule';
  boolean_operator: BooleanOperator;
  conditions: Array<Condition>;
  definition_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type SaveBaselineInfoInput = {
  baseline_info: Array<BaselineInfoInput>;
  pathway_id: Scalars['String'];
};

export type ScheduleTrackInput = {
  cancel_any_scheduled?: Maybe<Scalars['Boolean']>;
  pathway_id: Scalars['String'];
  scheduled_date: Scalars['String'];
  track_id: Scalars['String'];
};

export type ScheduleTrackPayload = Payload & {
  __typename?: 'ScheduleTrackPayload';
  code: Scalars['String'];
  id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ScheduledStepsPayload = Payload & {
  __typename?: 'ScheduledStepsPayload';
  code: Scalars['String'];
  steps: Array<Element>;
  success: Scalars['Boolean'];
};

export type ScheduledTrack = {
  __typename?: 'ScheduledTrack';
  created_by_user_id: Scalars['String'];
  created_date: Scalars['String'];
  id: Scalars['ID'];
  modified_date?: Maybe<Scalars['String']>;
  pathway_id: Scalars['String'];
  release_id: Scalars['String'];
  scheduled_date: Scalars['String'];
  status: Scalars['String'];
  tenant_id: Scalars['String'];
  title: Scalars['String'];
  track_definition_id: Scalars['String'];
};

export type ScheduledTracksPayload = Payload & {
  __typename?: 'ScheduledTracksPayload';
  code: Scalars['String'];
  scheduled_tracks: Array<ScheduledTrack>;
  success: Scalars['Boolean'];
};

export type SearchPatientsPayload = Payload & {
  __typename?: 'SearchPatientsPayload';
  code: Scalars['String'];
  patients: Array<User>;
  success: Scalars['Boolean'];
};

export type SessionMetadata = {
  __typename?: 'SessionMetadata';
  pathway_definition_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
};

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
  NotKnown = 'NOT_KNOWN'
}

export type SingleCalculationResult = {
  __typename?: 'SingleCalculationResult';
  status?: Maybe<Scalars['String']>;
  subresult_id: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  value_type?: Maybe<DataPointValueType>;
};

export type SliderConfig = {
  __typename?: 'SliderConfig';
  display_marks: Scalars['Boolean'];
  is_value_tooltip_on: Scalars['Boolean'];
  max: Scalars['Float'];
  max_label: Scalars['String'];
  min: Scalars['Float'];
  min_label: Scalars['String'];
  show_min_max_values: Scalars['Boolean'];
  step_value: Scalars['Float'];
};

export type SortingOutput = {
  __typename?: 'SortingOutput';
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type SortingParams = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type Stakeholder = {
  __typename?: 'Stakeholder';
  clinical_app_role: StakeholderClinicalAppRole;
  definition_id: Scalars['String'];
  id: Scalars['ID'];
  label: StakeholderLabel;
  release_id: Scalars['String'];
  version: Scalars['Float'];
};

export enum StakeholderClinicalAppRole {
  Caregiver = 'CAREGIVER',
  Patient = 'PATIENT',
  Physician = 'PHYSICIAN'
}

export type StakeholderLabel = {
  __typename?: 'StakeholderLabel';
  en: Scalars['String'];
};

export type StakeholdersPayload = Payload & {
  __typename?: 'StakeholdersPayload';
  code: Scalars['String'];
  stakeholders: Array<Stakeholder>;
  success: Scalars['Boolean'];
};

export type StartHostedActivitySessionInput = {
  cancel_url?: Maybe<Scalars['String']>;
  /** ISO 639-1 shortcode */
  language?: Maybe<Scalars['String']>;
  pathway_id: Scalars['String'];
  stakeholder_id: Scalars['String'];
  success_url?: Maybe<Scalars['String']>;
};

export type StartHostedActivitySessionPayload = Payload & {
  __typename?: 'StartHostedActivitySessionPayload';
  code: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  session_id: Scalars['String'];
  session_url: Scalars['String'];
  success: Scalars['Boolean'];
};

export type StartHostedActivitySessionViaHostedPagesLinkInput = {
  hosted_pages_link_id: Scalars['String'];
};

export type StartHostedPathwaySessionFromLinkInput = {
  id: Scalars['String'];
  patient_identifier?: Maybe<IdentifierInput>;
};

export type StartHostedPathwaySessionFromLinkPayload = Payload & {
  __typename?: 'StartHostedPathwaySessionFromLinkPayload';
  code: Scalars['String'];
  session_url: Scalars['String'];
  success: Scalars['Boolean'];
};

export type StartHostedPathwaySessionInput = {
  cancel_url?: Maybe<Scalars['String']>;
  data_points?: Maybe<Array<DataPointInput>>;
  /** ISO 639-1 shortcode */
  language?: Maybe<Scalars['String']>;
  pathway_definition_id: Scalars['String'];
  /** Unique id of the patient in Awell, if not provided, patient identifier will be tried to uniquely identify the patient. */
  patient_id?: Maybe<Scalars['String']>;
  /** If no patient_id is provided this field will be used to uniquely identify the patient. */
  patient_identifier?: Maybe<IdentifierInput>;
  success_url?: Maybe<Scalars['String']>;
  /** Time-to-live of the session in seconds. This defaults to the maximal value of 3600 seconds (one hour). */
  ttl?: Maybe<Scalars['Float']>;
};

export type StartHostedPathwaySessionPayload = Payload & {
  __typename?: 'StartHostedPathwaySessionPayload';
  code: Scalars['String'];
  pathway_id: Scalars['String'];
  session_id: Scalars['String'];
  session_url: Scalars['String'];
  stakeholder: HostedSessionStakeholder;
  success: Scalars['Boolean'];
};

export type StartPathwayInput = {
  data_points?: Maybe<Array<DataPointInput>>;
  pathway_definition_id: Scalars['String'];
  patient_id: Scalars['String'];
  release_id?: Maybe<Scalars['String']>;
};

export type StartPathwayPayload = Payload & {
  __typename?: 'StartPathwayPayload';
  code: Scalars['String'];
  pathway_id: Scalars['String'];
  stakeholders: Array<Stakeholder>;
  success: Scalars['Boolean'];
};

export type StartPathwayWithPatientIdentifierInput = {
  data_points?: Maybe<Array<DataPointInput>>;
  pathway_definition_id: Scalars['String'];
  patient_identifier: IdentifierInput;
  release_id?: Maybe<Scalars['String']>;
};

export type StartPathwayWithPatientIdentifierPayload = Payload & {
  __typename?: 'StartPathwayWithPatientIdentifierPayload';
  code: Scalars['String'];
  pathway_id: Scalars['String'];
  patient_id: Scalars['String'];
  stakeholders: Array<Stakeholder>;
  success: Scalars['Boolean'];
};

export type StopPathwayInput = {
  pathway_id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type StopTrackInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type StopTrackPayload = Payload & {
  __typename?: 'StopTrackPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  track: Element;
};

export type StringArrayFilter = {
  in?: Maybe<Array<Scalars['String']>>;
};

export type SubActivity = {
  __typename?: 'SubActivity';
  action: ActivityAction;
  date: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  error_category?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<ActivityObject>;
  subject: ActivitySubject;
  text?: Maybe<TranslatedText>;
};

export type SubmitChecklistInput = {
  activity_id: Scalars['String'];
};

export type SubmitChecklistPayload = Payload & {
  __typename?: 'SubmitChecklistPayload';
  activity: Activity;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SubmitFormResponseInput = {
  activity_id: Scalars['String'];
  response: Array<QuestionResponseInput>;
};

export type SubmitFormResponsePayload = Payload & {
  __typename?: 'SubmitFormResponsePayload';
  activity: Activity;
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activityCompleted: Activity;
  activityCreated: Activity;
  activityExpired: Activity;
  activityUpdated: Activity;
  apiCallCreated: ApiCall;
  apiCallUpdated: ApiCall;
  elementCompleted: Element;
  elementCreated: Element;
  elementUpdated: Element;
  pathwayUpdated: Pathway;
  sessionActivityCompleted: Activity;
  sessionActivityCreated: Activity;
  sessionActivityExpired: Activity;
  sessionActivityUpdated: Activity;
  sessionCompleted: HostedSession;
  sessionExpired: HostedSession;
  webhookCallCreated: WebhookCall;
  webhookCallUpdated: WebhookCall;
};


export type SubscriptionActivityCompletedArgs = {
  only_patient_activities?: Maybe<Scalars['Boolean']>;
  pathway_id?: Maybe<Scalars['String']>;
};


export type SubscriptionActivityCreatedArgs = {
  only_patient_activities?: Maybe<Scalars['Boolean']>;
  pathway_id?: Maybe<Scalars['String']>;
};


export type SubscriptionActivityExpiredArgs = {
  only_patient_activities?: Maybe<Scalars['Boolean']>;
  pathway_id?: Maybe<Scalars['String']>;
};


export type SubscriptionActivityUpdatedArgs = {
  only_patient_activities?: Maybe<Scalars['Boolean']>;
  pathway_id?: Maybe<Scalars['String']>;
};


export type SubscriptionApiCallCreatedArgs = {
  pathway_id: Scalars['String'];
};


export type SubscriptionApiCallUpdatedArgs = {
  pathway_id: Scalars['String'];
};


export type SubscriptionElementCompletedArgs = {
  element_type?: Maybe<ElementType>;
  pathway_id: Scalars['String'];
};


export type SubscriptionElementCreatedArgs = {
  element_type?: Maybe<ElementType>;
  pathway_id: Scalars['String'];
};


export type SubscriptionElementUpdatedArgs = {
  element_type?: Maybe<ElementType>;
  pathway_id: Scalars['String'];
};


export type SubscriptionPathwayUpdatedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionActivityCompletedArgs = {
  only_stakeholder_activities?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionSessionActivityCreatedArgs = {
  only_stakeholder_activities?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionSessionActivityExpiredArgs = {
  only_stakeholder_activities?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionSessionActivityUpdatedArgs = {
  only_stakeholder_activities?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionWebhookCallCreatedArgs = {
  pathway_id: Scalars['String'];
};


export type SubscriptionWebhookCallUpdatedArgs = {
  pathway_id: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  accent_color: Scalars['String'];
  hosted_page_title: Scalars['String'];
  identifier_systems?: Maybe<Array<IdentifierSystem>>;
  is_default: Scalars['Boolean'];
  logo_path: Scalars['String'];
  name: Scalars['String'];
};

export type TextFilter = {
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
};

export type TextFilterContains = {
  contains?: Maybe<Scalars['String']>;
};

export type TextFilterEquals = {
  eq?: Maybe<Scalars['String']>;
};

export type Track = {
  __typename?: 'Track';
  /** Whether the track can be triggered manually (i.e. via addTrack or scheduleTrack mutations) */
  can_trigger_manually?: Maybe<Scalars['Boolean']>;
  /** The definition ID of the Track, can be used for adding or scheduling */
  id: Scalars['ID'];
  release_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TracksPayload = Payload & {
  __typename?: 'TracksPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  tracks: Array<Track>;
};

export type TranslatedText = {
  __typename?: 'TranslatedText';
  en?: Maybe<Scalars['String']>;
};

export type UpdateBaselineInfoInput = {
  baseline_info: Array<BaselineInfoInput>;
  pathway_id: Scalars['String'];
};

export type UpdateEmrReportStatusInput = {
  reason: Scalars['String'];
  request_id: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateEmrReportStatusPayload = Payload & {
  __typename?: 'UpdateEmrReportStatusPayload';
  code: Scalars['String'];
  request?: Maybe<EmrRequest>;
  success: Scalars['Boolean'];
};

export type UpdatePatientDemographicsQueryInput = {
  /** Index from the array returned from the PDQ response, which was used to create the patient */
  created_patient_entry_index: Scalars['Float'];
  /** Patient ID of the created patient in Awell */
  created_patient_id: Scalars['String'];
  query_id: Scalars['String'];
};

export type UpdatePatientDemographicsQueryPayload = Payload & {
  __typename?: 'UpdatePatientDemographicsQueryPayload';
  code: Scalars['String'];
  created_patient_entry_index: Scalars['Float'];
  created_patient_id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdatePatientInput = {
  patient_id: Scalars['String'];
  profile: PatientProfileInput;
};

export type UpdatePatientLanguageInput = {
  /** ISO 639-1 shortcode */
  preferred_language: Scalars['String'];
};

export type UpdatePatientLanguagePayload = Payload & {
  __typename?: 'UpdatePatientLanguagePayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type UpdatePatientPayload = Payload & {
  __typename?: 'UpdatePatientPayload';
  code: Scalars['String'];
  patient?: Maybe<User>;
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  tenant_id: Scalars['String'];
};

export type UserInfoParams = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  address?: Maybe<Address>;
  birth_date?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  identifier?: Maybe<Array<Identifier>>;
  last_name?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  national_registry_number?: Maybe<Scalars['String']>;
  patient_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  /** Sex code as defined by ISO standard IEC_5218, 0 - NOT_KNOWN, 1 - MALE, 2 - FEMALE */
  sex?: Maybe<Sex>;
};

export enum UserQuestionType {
  Date = 'DATE',
  Description = 'DESCRIPTION',
  Email = 'EMAIL',
  LongText = 'LONG_TEXT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleChoiceGrid = 'MULTIPLE_CHOICE_GRID',
  MultipleSelect = 'MULTIPLE_SELECT',
  Number = 'NUMBER',
  ShortText = 'SHORT_TEXT',
  Signature = 'SIGNATURE',
  Slider = 'SLIDER',
  Telephone = 'TELEPHONE',
  YesNo = 'YES_NO'
}

export type VerifyIdentityInput = {
  dob?: Maybe<Scalars['String']>;
  pathway_id: Scalars['String'];
};

export type WebhookCall = {
  __typename?: 'WebhookCall';
  created_at: Scalars['String'];
  event_type: Scalars['String'];
  id: Scalars['ID'];
  pathway?: Maybe<ApiPathwayContext>;
  request: WebhookCallRequest;
  responses: Array<WebhookCallResponse>;
  status: Scalars['String'];
  webhook_id: Scalars['String'];
  webhook_name: Scalars['String'];
};

export type WebhookCallHeader = {
  __typename?: 'WebhookCallHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookCallPayload = Payload & {
  __typename?: 'WebhookCallPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  webhook_call: WebhookCall;
};

export type WebhookCallRequest = {
  __typename?: 'WebhookCallRequest';
  body: Scalars['String'];
  endpoint: Scalars['String'];
  headers: Array<WebhookCallHeader>;
  method: Scalars['String'];
};

export type WebhookCallResponse = {
  __typename?: 'WebhookCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  status: Scalars['Float'];
};

export type WebhookCallsPayload = Payload & {
  __typename?: 'WebhookCallsPayload';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  webhook_calls: Array<WebhookCall>;
};

export type PathwayLayout = {
  __typename?: 'PathwayLayout';
  activityFeedWidth: Scalars['Float'];
};

export type SaveBaselineInfoMutationVariables = Exact<{
  input: SaveBaselineInfoInput;
}>;


export type SaveBaselineInfoMutation = (
  { __typename?: 'Mutation' }
  & { saveBaselineInfo: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type EvaluateFormRulesMutationVariables = Exact<{
  input: EvaluateFormRulesInput;
}>;


export type EvaluateFormRulesMutation = (
  { __typename?: 'Mutation' }
  & { evaluateFormRules: (
    { __typename?: 'EvaluateFormRulesPayload' }
    & { results: Array<(
      { __typename?: 'QuestionRuleResult' }
      & Pick<QuestionRuleResult, 'question_id' | 'rule_id' | 'satisfied'>
    )> }
  ) }
);

export type PathwayFragment = (
  { __typename?: 'Pathway' }
  & Pick<Pathway, 'id' | 'title' | 'status' | 'pathway_definition_id' | 'status_explanation'>
  & { patient: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'name'>
    )> }
  ), tracks: Array<(
    { __typename?: 'Track' }
    & Pick<Track, 'id' | 'title'>
  )>, activities?: Maybe<Array<(
    { __typename?: 'Activity' }
    & ActivityFragment
  )>> }
);

export type PatientFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { profile?: Maybe<(
    { __typename?: 'UserProfile' }
    & PatientProfileFragment
  )> }
);

export type PatientPathwayFragment = (
  { __typename?: 'PatientPathway' }
  & Pick<PatientPathway, 'id' | 'title' | 'pathway_definition_id' | 'status_explanation' | 'status' | 'release_id' | 'stop_date' | 'complete_date' | 'total_activities' | 'active_activities' | 'failed_activities' | 'latest_activity_date' | 'latest_activity_title' | 'latest_activity_type'>
);

export type PatientProfileFragment = (
  { __typename?: 'UserProfile' }
  & Pick<UserProfile, 'email' | 'sex' | 'birth_date' | 'first_name' | 'last_name' | 'name' | 'phone' | 'mobile_phone' | 'preferred_language' | 'patient_code' | 'national_registry_number'>
  & { identifier?: Maybe<Array<(
    { __typename?: 'Identifier' }
    & Pick<Identifier, 'system' | 'value'>
  )>>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'street' | 'city' | 'zip' | 'state' | 'country'>
  )> }
);

export type GetActivitiesQueryVariables = Exact<{
  filters?: Maybe<FilterActivitiesParams>;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
}>;


export type GetActivitiesQuery = (
  { __typename?: 'Query' }
  & { activities: (
    { __typename?: 'ActivitiesPayload' }
    & { pagination?: Maybe<(
      { __typename?: 'PaginationOutput' }
      & Pick<PaginationOutput, 'offset' | 'count' | 'total_count'>
    )>, sorting?: Maybe<(
      { __typename?: 'SortingOutput' }
      & Pick<SortingOutput, 'field' | 'direction'>
    )>, activities: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )>, metadata?: Maybe<(
      { __typename?: 'ActivityMetadata' }
      & { stakeholders?: Maybe<Array<(
        { __typename?: 'ActivityObject' }
        & Pick<ActivityObject, 'id' | 'name' | 'type'>
      )>> }
    )> }
  ) }
);

export type ActivityFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'stream_id' | 'icon_url' | 'action' | 'date' | 'status' | 'resolution' | 'reference_id' | 'container_name' | 'isUserActivity'>
  & { subject: (
    { __typename?: 'ActivitySubject' }
    & Pick<ActivitySubject, 'id' | 'type' | 'name'>
  ), object: (
    { __typename?: 'ActivityObject' }
    & Pick<ActivityObject, 'id' | 'type' | 'name'>
  ), indirect_object?: Maybe<(
    { __typename?: 'ActivityObject' }
    & Pick<ActivityObject, 'id' | 'type' | 'name'>
  )>, track?: Maybe<(
    { __typename?: 'ActivityTrack' }
    & Pick<ActivityTrack, 'id' | 'title'>
  )>, label?: Maybe<(
    { __typename?: 'ActivityLabel' }
    & Pick<ActivityLabel, 'id' | 'text' | 'color'>
  )>, sub_activities: Array<(
    { __typename?: 'SubActivity' }
    & Pick<SubActivity, 'id' | 'date' | 'action' | 'error' | 'error_category'>
    & { text?: Maybe<(
      { __typename?: 'TranslatedText' }
      & Pick<TranslatedText, 'en'>
    )>, subject: (
      { __typename?: 'ActivitySubject' }
      & Pick<ActivitySubject, 'id' | 'type' | 'name'>
    ), object?: Maybe<(
      { __typename?: 'ActivityObject' }
      & Pick<ActivityObject, 'id' | 'type' | 'name'>
    )> }
  )>, context?: Maybe<(
    { __typename?: 'PathwayContext' }
    & Pick<PathwayContext, 'instance_id' | 'pathway_id' | 'track_id' | 'step_id' | 'action_id'>
  )>, stakeholders?: Maybe<Array<(
    { __typename?: 'ActivityObject' }
    & Pick<ActivityObject, 'id' | 'type' | 'name' | 'email'>
  )>> }
);

export type OnActivityCompletedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnActivityCompletedSubscription = (
  { __typename?: 'Subscription' }
  & { activityCompleted: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type OnActivityCreatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnActivityCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { activityCreated: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type OnActivityExpiredSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnActivityExpiredSubscription = (
  { __typename?: 'Subscription' }
  & { activityExpired: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type OnActivityUpdatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnActivityUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { activityUpdated: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type PathwayActivitiesQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type PathwayActivitiesQuery = (
  { __typename?: 'Query' }
  & { pathwayActivities: (
    { __typename?: 'ActivitiesPayload' }
    & Pick<ActivitiesPayload, 'success'>
    & { activities: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )> }
  ) }
);

export type AddTrackMutationVariables = Exact<{
  input: AddTrackInput;
}>;


export type AddTrackMutation = (
  { __typename?: 'Mutation' }
  & { addTrack: (
    { __typename?: 'AddTrackPayload' }
    & Pick<AddTrackPayload, 'success'>
  ) }
);

export type GetAdHocTracksQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetAdHocTracksQuery = (
  { __typename?: 'Query' }
  & { adHocTracksByPathway: (
    { __typename?: 'TracksPayload' }
    & { tracks: Array<(
      { __typename?: 'Track' }
      & Pick<Track, 'id' | 'title'>
    )> }
  ) }
);

export type ApiCallQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApiCallQuery = (
  { __typename?: 'Query' }
  & { apiCall: (
    { __typename?: 'ApiCallPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id' | 'status' | 'title'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'method' | 'endpoint' | 'body'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'key' | 'value'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'body' | 'date'>
      )> }
    ) }
  ) }
);

export type GetBaselineInfoQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetBaselineInfoQuery = (
  { __typename?: 'Query' }
  & { baselineInfo: (
    { __typename?: 'BaselineInfoPayload' }
    & { baselineDataPoints: Array<(
      { __typename?: 'BaselineDataPoint' }
      & Pick<BaselineDataPoint, 'value'>
      & { definition: (
        { __typename?: 'DataPointDefinition' }
        & Pick<DataPointDefinition, 'id' | 'title' | 'category' | 'key' | 'valueType' | 'unit'>
        & { possibleValues?: Maybe<Array<(
          { __typename?: 'DataPointPossibleValue' }
          & Pick<DataPointPossibleValue, 'label' | 'value'>
        )>>, range?: Maybe<(
          { __typename?: 'Range' }
          & Pick<Range, 'min' | 'max'>
        )> }
      ) }
    )> }
  ) }
);

export type GetActionCalculationIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetActionCalculationIdQuery = (
  { __typename?: 'Query' }
  & { calculationAction: (
    { __typename?: 'ActionPayload' }
    & Pick<ActionPayload, 'calculationId'>
  ) }
);

export type GetCalculationResultsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
  activity_id: Scalars['String'];
}>;


export type GetCalculationResultsQuery = (
  { __typename?: 'Query' }
  & { calculationResults: (
    { __typename?: 'CalculationResultsPayload' }
    & { result: Array<(
      { __typename?: 'SingleCalculationResult' }
      & Pick<SingleCalculationResult, 'status' | 'subresult_id' | 'unit' | 'value' | 'value_type'>
    )> }
  ) }
);

export type GetChecklistQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetChecklistQuery = (
  { __typename?: 'Query' }
  & { checklist: (
    { __typename?: 'ChecklistPayload' }
    & { checklist?: Maybe<(
      { __typename?: 'Checklist' }
      & Pick<Checklist, 'title' | 'items'>
    )> }
  ) }
);

export type SubmitChecklistMutationVariables = Exact<{
  input: SubmitChecklistInput;
}>;


export type SubmitChecklistMutation = (
  { __typename?: 'Mutation' }
  & { submitChecklist: (
    { __typename?: 'SubmitChecklistPayload' }
    & { activity: (
      { __typename?: 'Activity' }
      & ActivityFragment
    ) }
  ) }
);

export type GeneratedClinicalNoteFragment = (
  { __typename?: 'GeneratedClinicalNote' }
  & Pick<GeneratedClinicalNote, 'id'>
  & { narratives: Array<(
    { __typename?: 'GeneratedClinicalNoteNarrative' }
    & Pick<GeneratedClinicalNoteNarrative, 'id' | 'key' | 'title' | 'body'>
  )>, context: Array<(
    { __typename?: 'GeneratedClinicalNoteContextField' }
    & Pick<GeneratedClinicalNoteContextField, 'key' | 'value'>
  )> }
);

export type GetGeneratedClinicalNoteQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGeneratedClinicalNoteQuery = (
  { __typename?: 'Query' }
  & { clinicalNote: (
    { __typename?: 'ClinicalNotePayload' }
    & { clinical_note: (
      { __typename?: 'GeneratedClinicalNote' }
      & GeneratedClinicalNoteFragment
    ) }
  ) }
);

export type CreatePatientMutationVariables = Exact<{
  input: CreatePatientInput;
}>;


export type CreatePatientMutation = (
  { __typename?: 'Mutation' }
  & { createPatient: (
    { __typename?: 'CreatePatientPayload' }
    & { patient?: Maybe<(
      { __typename?: 'User' }
      & PatientFragment
    )> }
  ) }
);

export type DeletePathwayMutationVariables = Exact<{
  input: DeletePathwayInput;
}>;


export type DeletePathwayMutation = (
  { __typename?: 'Mutation' }
  & { deletePathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type DeletePatientMutationVariables = Exact<{
  input: DeletePatientInput;
}>;


export type DeletePatientMutation = (
  { __typename?: 'Mutation' }
  & { deletePatient: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type GetEmrReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEmrReportQuery = (
  { __typename?: 'Query' }
  & { emrReport: (
    { __typename?: 'EmrReportPayload' }
    & { report?: Maybe<(
      { __typename?: 'EmrReport' }
      & Pick<EmrReport, 'id' | 'message_html'>
      & { metadata?: Maybe<Array<(
        { __typename?: 'EmrReportMetadataField' }
        & Pick<EmrReportMetadataField, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type GetExtensionActivityRecordQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetExtensionActivityRecordQuery = (
  { __typename?: 'Query' }
  & { extensionActivityRecord: (
    { __typename?: 'ExtensionActivityRecordPayload' }
    & { record: (
      { __typename?: 'ExtensionActivityRecord' }
      & Pick<ExtensionActivityRecord, 'id' | 'activity_id' | 'pathway_id' | 'plugin_key' | 'plugin_action_key' | 'date'>
      & { fields: Array<(
        { __typename?: 'ExtensionActionField' }
        & Pick<ExtensionActionField, 'id' | 'type' | 'label' | 'value'>
      )>, settings?: Maybe<Array<(
        { __typename?: 'PluginActionSettingsProperty' }
        & Pick<PluginActionSettingsProperty, 'value' | 'label' | 'key'>
      )>>, data_points: Array<(
        { __typename?: 'ExtensionDataPoint' }
        & Pick<ExtensionDataPoint, 'label' | 'value'>
      )> }
    ) }
  ) }
);

export type GetFormResponseQueryVariables = Exact<{
  pathway_id: Scalars['String'];
  activity_id: Scalars['String'];
}>;


export type GetFormResponseQuery = (
  { __typename?: 'Query' }
  & { formResponse: (
    { __typename?: 'FormResponsePayload' }
    & { response: (
      { __typename?: 'FormResponse' }
      & { answers: Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'question_id' | 'value' | 'value_type'>
      )> }
    ) }
  ) }
);

export type SubmitFormResponseMutationVariables = Exact<{
  input: SubmitFormResponseInput;
}>;


export type SubmitFormResponseMutation = (
  { __typename?: 'Mutation' }
  & { submitFormResponse: (
    { __typename?: 'SubmitFormResponsePayload' }
    & { activity: (
      { __typename?: 'Activity' }
      & ActivityFragment
    ) }
  ) }
);

export type GetFormQueryVariables = Exact<{
  id: Scalars['String'];
  careflowId: Scalars['String'];
}>;


export type GetFormQuery = (
  { __typename?: 'Query' }
  & { form: (
    { __typename?: 'FormPayload' }
    & { form?: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title' | 'trademark'>
      & { previous_answers?: Maybe<Array<(
        { __typename?: 'PreviousAnswers' }
        & Pick<PreviousAnswers, 'activity_id' | 'date'>
        & { answers: Array<(
          { __typename?: 'Answer' }
          & Pick<Answer, 'question_id' | 'value' | 'value_type'>
        )> }
      )>>, questions: Array<(
        { __typename?: 'Question' }
        & QuestionFragment
      )> }
    )> }
  ) }
);

export type QuestionFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'title' | 'dataPointValueType' | 'questionType' | 'userQuestionType'>
  & { options?: Maybe<Array<(
    { __typename?: 'Option' }
    & Pick<Option, 'id' | 'value' | 'value_string' | 'label'>
  )>>, questionConfig?: Maybe<(
    { __typename?: 'QuestionConfig' }
    & Pick<QuestionConfig, 'recode_enabled' | 'use_select' | 'mandatory'>
    & { slider?: Maybe<(
      { __typename?: 'SliderConfig' }
      & Pick<SliderConfig, 'min' | 'max' | 'step_value' | 'display_marks' | 'min_label' | 'max_label' | 'is_value_tooltip_on' | 'show_min_max_values'>
    )>, phone?: Maybe<(
      { __typename?: 'PhoneConfig' }
      & Pick<PhoneConfig, 'default_country' | 'available_countries'>
    )>, number?: Maybe<(
      { __typename?: 'NumberConfig' }
      & { range?: Maybe<(
        { __typename?: 'RangeConfig' }
        & Pick<RangeConfig, 'min' | 'max' | 'enabled'>
      )> }
    )>, multiple_select?: Maybe<(
      { __typename?: 'MultipleSelectConfig' }
      & { range?: Maybe<(
        { __typename?: 'ChoiceRangeConfig' }
        & Pick<ChoiceRangeConfig, 'min' | 'max' | 'enabled'>
      )>, exclusive_option?: Maybe<(
        { __typename?: 'ExclusiveOptionConfig' }
        & Pick<ExclusiveOptionConfig, 'option_id' | 'enabled'>
      )> }
    )>, date?: Maybe<(
      { __typename?: 'DateConfig' }
      & Pick<DateConfig, 'allowed_dates' | 'include_date_of_response'>
    )> }
  )> }
);

export type GetHostedPagesLinkQueryVariables = Exact<{
  pathwayId: Scalars['String'];
  stakeholderId: Scalars['String'];
}>;


export type GetHostedPagesLinkQuery = (
  { __typename?: 'Query' }
  & { hostedPagesLink: (
    { __typename?: 'HostedPagesLinkPayload' }
    & Pick<HostedPagesLinkPayload, 'code' | 'success'>
    & { hosted_pages_link?: Maybe<(
      { __typename?: 'HostedPagesLink' }
      & Pick<HostedPagesLink, 'url'>
    )> }
  ) }
);

export type GetOrchestrationFactsQueryVariables = Exact<{
  filters: PathwayFactsFilters;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
}>;


export type GetOrchestrationFactsQuery = (
  { __typename?: 'Query' }
  & { pathwayFacts: (
    { __typename?: 'OrchestrationFactsPayload' }
    & Pick<OrchestrationFactsPayload, 'code' | 'success'>
    & { facts: Array<(
      { __typename?: 'OrchestrationFact' }
      & Pick<OrchestrationFact, 'date' | 'level' | 'pathway_id' | 'content'>
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationOutput' }
      & Pick<PaginationOutput, 'offset' | 'count' | 'total_count'>
    )>, sorting?: Maybe<(
      { __typename?: 'SortingOutput' }
      & Pick<SortingOutput, 'field' | 'direction'>
    )> }
  ) }
);

export type GetApiCallQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetApiCallQuery = (
  { __typename?: 'Query' }
  & { apiCall: (
    { __typename?: 'ApiCallPayload' }
    & Pick<ApiCallPayload, 'code' | 'success'>
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'title' | 'created_at' | 'status' | 'id'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'value' | 'key'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
      )> }
    ) }
  ) }
);

export type GetApiCallsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetApiCallsQuery = (
  { __typename?: 'Query' }
  & { apiCalls: (
    { __typename?: 'ApiCallsPayload' }
    & Pick<ApiCallsPayload, 'code' | 'success'>
    & { api_calls: Array<(
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'title' | 'created_at' | 'status' | 'id'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'value' | 'key'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
      )> }
    )> }
  ) }
);

export type OnApiCallCreatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnApiCallCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { apiCallCreated: (
    { __typename?: 'ApiCall' }
    & Pick<ApiCall, 'status' | 'created_at' | 'id' | 'title'>
    & { request: (
      { __typename?: 'ApiCallRequest' }
      & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
      & { headers: Array<(
        { __typename?: 'ApiCallHeader' }
        & Pick<ApiCallHeader, 'value' | 'key'>
      )> }
    ), responses: Array<(
      { __typename?: 'ApiCallResponse' }
      & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
    )> }
  ) }
);

export type OnApiCallUpdatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnApiCallUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { apiCallUpdated: (
    { __typename?: 'ApiCall' }
    & Pick<ApiCall, 'status' | 'created_at' | 'id' | 'title'>
    & { request: (
      { __typename?: 'ApiCallRequest' }
      & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
      & { headers: Array<(
        { __typename?: 'ApiCallHeader' }
        & Pick<ApiCallHeader, 'value' | 'key'>
      )> }
    ), responses: Array<(
      { __typename?: 'ApiCallResponse' }
      & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
    )> }
  ) }
);

export type GetPathwayWebhookCallQueryVariables = Exact<{
  webhook_call_id: Scalars['String'];
}>;


export type GetPathwayWebhookCallQuery = (
  { __typename?: 'Query' }
  & { webhookCall: (
    { __typename?: 'WebhookCallPayload' }
    & { webhook_call: (
      { __typename?: 'WebhookCall' }
      & Pick<WebhookCall, 'id' | 'event_type' | 'webhook_id' | 'webhook_name' | 'status' | 'created_at'>
      & { responses: Array<(
        { __typename?: 'WebhookCallResponse' }
        & Pick<WebhookCallResponse, 'date' | 'status' | 'body'>
      )>, request: (
        { __typename?: 'WebhookCallRequest' }
        & Pick<WebhookCallRequest, 'body' | 'method' | 'endpoint'>
        & { headers: Array<(
          { __typename?: 'WebhookCallHeader' }
          & Pick<WebhookCallHeader, 'key' | 'value'>
        )> }
      ), pathway?: Maybe<(
        { __typename?: 'ApiPathwayContext' }
        & Pick<ApiPathwayContext, 'id' | 'pathway_definition_id' | 'start_date'>
      )> }
    ) }
  ) }
);

export type GetPathwayWebhookCallsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetPathwayWebhookCallsQuery = (
  { __typename?: 'Query' }
  & { webhookCalls: (
    { __typename?: 'WebhookCallsPayload' }
    & { webhook_calls: Array<(
      { __typename?: 'WebhookCall' }
      & WebhookCallFragment
    )> }
  ) }
);

export type OnWebhookCallCreatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnWebhookCallCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { webhookCallCreated: (
    { __typename?: 'WebhookCall' }
    & WebhookCallFragment
  ) }
);

export type OnWebhookCallUpdatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type OnWebhookCallUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { webhookCallUpdated: (
    { __typename?: 'WebhookCall' }
    & WebhookCallFragment
  ) }
);

export type WebhookCallFragment = (
  { __typename?: 'WebhookCall' }
  & Pick<WebhookCall, 'id' | 'webhook_name' | 'webhook_id' | 'status' | 'event_type' | 'created_at'>
  & { request: (
    { __typename?: 'WebhookCallRequest' }
    & Pick<WebhookCallRequest, 'method' | 'endpoint' | 'body'>
    & { headers: Array<(
      { __typename?: 'WebhookCallHeader' }
      & Pick<WebhookCallHeader, 'key' | 'value'>
    )> }
  ), responses: Array<(
    { __typename?: 'WebhookCallResponse' }
    & Pick<WebhookCallResponse, 'status' | 'body' | 'date'>
  )>, pathway?: Maybe<(
    { __typename?: 'ApiPathwayContext' }
    & Pick<ApiPathwayContext, 'id' | 'pathway_definition_id' | 'patient_id' | 'start_date'>
  )> }
);

export type GetMessageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMessageQuery = (
  { __typename?: 'Query' }
  & { message: (
    { __typename?: 'MessagePayload' }
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'subject' | 'format'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'type' | 'url'>
      )>> }
    )> }
  ) }
);

export type MarkMessageAsReadMutationVariables = Exact<{
  input: MarkMessageAsReadInput;
}>;


export type MarkMessageAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markMessageAsRead: (
    { __typename?: 'MarkMessageAsReadPayload' }
    & { activity: (
      { __typename?: 'Activity' }
      & ActivityFragment
    ) }
  ) }
);

export type GetOrchestrationFactsFromPromptQueryVariables = Exact<{
  pathway_id: Scalars['String'];
  prompt: Scalars['String'];
}>;


export type GetOrchestrationFactsFromPromptQuery = (
  { __typename?: 'Query' }
  & { getOrchestrationFactsFromPrompt: (
    { __typename: 'OrchestrationFactsPromptPayload' }
    & Pick<OrchestrationFactsPromptPayload, 'response' | 'success'>
  ) }
);

export type GetPathwayQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetPathwayQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway?: Maybe<(
      { __typename?: 'Pathway' }
      & PathwayFragment
    )> }
  ) }
);

export type OnPathwayUpdatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['ID'];
}>;


export type OnPathwayUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { pathwayUpdated: (
    { __typename?: 'Pathway' }
    & PathwayFragment
  ) }
);

export type ElementFragment = (
  { __typename?: 'Element' }
  & Pick<Element, 'id' | 'parent_id' | 'name' | 'status' | 'type' | 'activity_type' | 'start_date' | 'end_date'>
  & { label?: Maybe<(
    { __typename?: 'ActivityLabel' }
    & Pick<ActivityLabel, 'id' | 'color' | 'text'>
  )>, stakeholders: Array<(
    { __typename?: 'ElementStakeholder' }
    & Pick<ElementStakeholder, 'id' | 'name'>
  )>, context: (
    { __typename?: 'PathwayContext' }
    & Pick<PathwayContext, 'instance_id' | 'pathway_id' | 'track_id' | 'step_id' | 'action_id'>
  ) }
);

export type OnElementCompletedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
  element_type?: Maybe<ElementType>;
}>;


export type OnElementCompletedSubscription = (
  { __typename?: 'Subscription' }
  & { elementCompleted: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type OnElementCreatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
  element_type?: Maybe<ElementType>;
}>;


export type OnElementCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { elementCreated: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type OnElementUpdatedSubscriptionVariables = Exact<{
  pathway_id: Scalars['String'];
  element_type?: Maybe<ElementType>;
}>;


export type OnElementUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { elementUpdated: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type PathwayElementsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type PathwayElementsQuery = (
  { __typename?: 'Query' }
  & { pathwayElements: (
    { __typename?: 'ElementsPayload' }
    & { elements: Array<(
      { __typename?: 'Element' }
      & ElementFragment
    )> }
  ) }
);

export type PathwayStepActivitiesQueryVariables = Exact<{
  pathway_id: Scalars['String'];
  step_id: Scalars['String'];
}>;


export type PathwayStepActivitiesQuery = (
  { __typename?: 'Query' }
  & { pathwayStepActivities: (
    { __typename?: 'ActivitiesPayload' }
    & Pick<ActivitiesPayload, 'success'>
    & { activities: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )> }
  ) }
);

export type GetPatientQueryVariables = Exact<{
  patient_id: Scalars['String'];
}>;


export type GetPatientQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'PatientPayload' }
    & { patient?: Maybe<(
      { __typename?: 'User' }
      & PatientFragment
    )> }
  ) }
);

export type GetPatientDemographicsQueryConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPatientDemographicsQueryConfigurationQuery = (
  { __typename?: 'Query' }
  & { patientDemographicsQueryConfiguration: (
    { __typename?: 'PatientDemographicsQueryConfigurationPayload' }
    & Pick<PatientDemographicsQueryConfigurationPayload, 'is_enabled' | 'input_box_text'>
  ) }
);

export type RequestPatientDemographicsMutationVariables = Exact<{
  input: PatientDemographicsInput;
}>;


export type RequestPatientDemographicsMutation = (
  { __typename?: 'Mutation' }
  & { requestPatientDemographics: (
    { __typename?: 'PatientDemographicsPayload' }
    & Pick<PatientDemographicsPayload, 'query_id' | 'total' | 'status'>
    & { entry?: Maybe<Array<(
      { __typename?: 'UserProfile' }
      & PatientProfileFragment
    )>> }
  ) }
);

export type UpdatePatientDemographicsQueryMutationVariables = Exact<{
  input: UpdatePatientDemographicsQueryInput;
}>;


export type UpdatePatientDemographicsQueryMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientDemographicsQuery: (
    { __typename?: 'UpdatePatientDemographicsQueryPayload' }
    & Pick<UpdatePatientDemographicsQueryPayload, 'created_patient_entry_index' | 'created_patient_id'>
  ) }
);

export type GetPatientPathwaysQueryVariables = Exact<{
  patient_id: Scalars['String'];
}>;


export type GetPatientPathwaysQuery = (
  { __typename?: 'Query' }
  & { patientPathways: (
    { __typename?: 'PatientPathwaysPayload' }
    & { patientPathways: Array<(
      { __typename?: 'PatientPathway' }
      & PatientPathwayFragment
    )> }
  ) }
);

export type GetPatientsQueryVariables = Exact<{
  filters?: Maybe<FilterPatients>;
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
}>;


export type GetPatientsQuery = (
  { __typename?: 'Query' }
  & { patients: (
    { __typename?: 'PatientsPayload' }
    & { patients: Array<(
      { __typename?: 'User' }
      & PatientFragment
    )>, pagination?: Maybe<(
      { __typename?: 'PaginationOutput' }
      & Pick<PaginationOutput, 'offset' | 'count' | 'total_count'>
    )>, sorting?: Maybe<(
      { __typename?: 'SortingOutput' }
      & Pick<SortingOutput, 'field' | 'direction'>
    )> }
  ) }
);

export type GetDetailsForPublishedPathwayDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDetailsForPublishedPathwayDefinitionsQuery = (
  { __typename?: 'Query' }
  & { getStatusForPublishedPathwayDefinitions: (
    { __typename?: 'PublishedPathwayDefinitionsPayload' }
    & { publishedPathwayDefinitions: Array<(
      { __typename?: 'PublishedPathwayDefinition' }
      & Pick<PublishedPathwayDefinition, 'id' | 'title' | 'version' | 'total_activities' | 'active_activities' | 'failed_activities' | 'total_stakeholders' | 'stakeholders_with_pending_activities_list' | 'total_patients' | 'patients_with_pending_activities'>
    )> }
  ) }
);

export type GetPublishedPathwayDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublishedPathwayDefinitionsQuery = (
  { __typename?: 'Query' }
  & { publishedPathwayDefinitions: (
    { __typename?: 'PublishedPathwayDefinitionsPayload' }
    & { publishedPathwayDefinitions: Array<(
      { __typename?: 'PublishedPathwayDefinition' }
      & Pick<PublishedPathwayDefinition, 'id' | 'title' | 'version'>
      & { dataPointDefinitions: Array<(
        { __typename?: 'DataPointDefinition' }
        & Pick<DataPointDefinition, 'id' | 'key' | 'valueType' | 'category' | 'title' | 'optional'>
        & { possibleValues?: Maybe<Array<(
          { __typename?: 'DataPointPossibleValue' }
          & Pick<DataPointPossibleValue, 'label' | 'value'>
        )>> }
      )> }
    )> }
  ) }
);

export type GenerateRetoolEmbedUrlQueryVariables = Exact<{
  landingPageUuid: Scalars['String'];
  groupIds: Array<Scalars['String']> | Scalars['String'];
  userInfo: UserInfoParams;
}>;


export type GenerateRetoolEmbedUrlQuery = (
  { __typename?: 'Query' }
  & { generateRetoolEmbedUrl: (
    { __typename?: 'GenerateRetoolEmbedUrlPayload' }
    & Pick<GenerateRetoolEmbedUrlPayload, 'url'>
  ) }
);

export type RetryActivityMutationVariables = Exact<{
  input: RetryActivityInput;
}>;


export type RetryActivityMutation = (
  { __typename?: 'Mutation' }
  & { retryActivity: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryAllApiCallsMutationVariables = Exact<{
  input: RetryAllApiCallsInput;
}>;


export type RetryAllApiCallsMutation = (
  { __typename?: 'Mutation' }
  & { retryAllApiCalls: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success' | 'code'>
  ) }
);

export type RetryAllFailedApiCallsMutationVariables = Exact<{
  input: RetryAllFailedApiCallsInput;
}>;


export type RetryAllFailedApiCallsMutation = (
  { __typename?: 'Mutation' }
  & { retryAllFailedApiCalls: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success' | 'code'>
  ) }
);

export type RetryAllFailedWebhooksMutationVariables = Exact<{
  input: RetryAllFailedWebhookCallsInput;
}>;


export type RetryAllFailedWebhooksMutation = (
  { __typename?: 'Mutation' }
  & { retryAllFailedWebhookCalls: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryAllFailedWebhookCallsForPathwayDefinitionMutationVariables = Exact<{
  input: RetryAllFailedWebhookCallsForPathwayDefinitionInput;
}>;


export type RetryAllFailedWebhookCallsForPathwayDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { retryAllFailedWebhookCallsForPathwayDefinition: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryAllWebhookCallsMutationVariables = Exact<{
  input: RetryAllWebhookCallsInput;
}>;


export type RetryAllWebhookCallsMutation = (
  { __typename?: 'Mutation' }
  & { retryAllWebhookCalls: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryApiCallMutationVariables = Exact<{
  input: RetryApiCallInput;
}>;


export type RetryApiCallMutation = (
  { __typename?: 'Mutation' }
  & { retryApiCall: (
    { __typename?: 'RetryApiCallPayload' }
    & Pick<RetryApiCallPayload, 'success' | 'code'>
  ) }
);

export type RetryPushToEmrMutationVariables = Exact<{
  input: RetryPushToEmrInput;
}>;


export type RetryPushToEmrMutation = (
  { __typename?: 'Mutation' }
  & { retryPushToEmr: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryWebhookCallMutationVariables = Exact<{
  input: RetryWebhookCallInput;
}>;


export type RetryWebhookCallMutation = (
  { __typename?: 'Mutation' }
  & { retryWebhookCall: (
    { __typename?: 'RetryWebhookCallPayload' }
    & Pick<RetryWebhookCallPayload, 'success' | 'code'>
  ) }
);

export type GetScheduledStepsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetScheduledStepsQuery = (
  { __typename?: 'Query' }
  & { scheduledSteps: (
    { __typename?: 'ScheduledStepsPayload' }
    & Pick<ScheduledStepsPayload, 'success'>
    & { steps: Array<(
      { __typename?: 'Element' }
      & Pick<Element, 'id' | 'parent_id' | 'name' | 'start_date' | 'status'>
    )> }
  ) }
);

export type GetScheduledTracksQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetScheduledTracksQuery = (
  { __typename?: 'Query' }
  & { scheduledTracksForPathway: (
    { __typename?: 'ScheduledTracksPayload' }
    & Pick<ScheduledTracksPayload, 'success'>
    & { scheduled_tracks: Array<(
      { __typename?: 'ScheduledTrack' }
      & Pick<ScheduledTrack, 'id' | 'title' | 'scheduled_date'>
    )> }
  ) }
);

export type GetStakeholdersByPathwayDefinitionIdsQueryVariables = Exact<{
  pathway_definition_ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStakeholdersByPathwayDefinitionIdsQuery = (
  { __typename?: 'Query' }
  & { stakeholdersByPathwayDefinitionIds: (
    { __typename?: 'StakeholdersPayload' }
    & { stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )> }
  ) }
);

export type StartPathwayMutationVariables = Exact<{
  input: StartPathwayInput;
}>;


export type StartPathwayMutation = (
  { __typename?: 'Mutation' }
  & { startPathway: (
    { __typename?: 'StartPathwayPayload' }
    & Pick<StartPathwayPayload, 'pathway_id'>
  ) }
);

export type StopPathwayMutationVariables = Exact<{
  input: StopPathwayInput;
}>;


export type StopPathwayMutation = (
  { __typename?: 'Mutation' }
  & { stopPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type StopTrackMutationVariables = Exact<{
  input: StopTrackInput;
}>;


export type StopTrackMutation = (
  { __typename?: 'Mutation' }
  & { stopTrack: (
    { __typename?: 'StopTrackPayload' }
    & Pick<StopTrackPayload, 'success'>
  ) }
);

export type UpdatePatientMutationVariables = Exact<{
  input: UpdatePatientInput;
}>;


export type UpdatePatientMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'UpdatePatientPayload' }
    & { patient?: Maybe<(
      { __typename?: 'User' }
      & PatientFragment
    )> }
  ) }
);

export type WebhookCallsForPathwayDefinitionQueryVariables = Exact<{
  pathway_definition_id: Scalars['String'];
}>;


export type WebhookCallsForPathwayDefinitionQuery = (
  { __typename?: 'Query' }
  & { webhookCallsForPathwayDefinition: (
    { __typename?: 'WebhookCallsPayload' }
    & { webhook_calls: Array<(
      { __typename?: 'WebhookCall' }
      & WebhookCallFragment
    )> }
  ) }
);

export type WebhookCallsForTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type WebhookCallsForTenantQuery = (
  { __typename?: 'Query' }
  & { webhookCallsForTenant: (
    { __typename?: 'WebhookCallsPayload' }
    & { webhook_calls: Array<(
      { __typename?: 'WebhookCall' }
      & WebhookCallFragment
    )> }
  ) }
);

export type WhoamiQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoamiQuery = (
  { __typename?: 'Query' }
  & { whoami: (
    { __typename?: 'CurrentUserPayload' }
    & { user: (
      { __typename?: 'CurrentUser' }
      & Pick<CurrentUser, 'id' | 'tenant_id'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'email' | 'name'>
      )>, tenant: (
        { __typename?: 'Tenant' }
        & Pick<Tenant, 'is_default' | 'name'>
        & { identifier_systems?: Maybe<Array<(
          { __typename?: 'IdentifierSystem' }
          & Pick<IdentifierSystem, 'name' | 'display_name' | 'system'>
        )>> }
      ) }
    ) }
  ) }
);

export type PageTitleQueryVariables = Exact<{ [key: string]: never; }>;


export type PageTitleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pageTitle'>
);

export type PathwayLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type PathwayLayoutQuery = (
  { __typename?: 'Query' }
  & { pathwayLayout?: Maybe<(
    { __typename?: 'PathwayLayout' }
    & Pick<PathwayLayout, 'activityFeedWidth'>
  )> }
);

export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  id
  stream_id
  icon_url
  subject {
    id
    type
    name
  }
  action
  date
  object {
    id
    type
    name
  }
  indirect_object {
    id
    type
    name
  }
  status
  resolution
  reference_id
  track {
    id
    title
  }
  container_name
  label {
    id
    text
    color
  }
  sub_activities {
    id
    date
    action
    error
    error_category
    text {
      en
    }
    subject {
      id
      type
      name
    }
    object {
      id
      type
      name
    }
  }
  isUserActivity
  context {
    instance_id
    pathway_id
    track_id
    step_id
    action_id
  }
  stakeholders {
    id
    type
    name
    email
  }
}
    `;
export const PathwayFragmentDoc = gql`
    fragment Pathway on Pathway {
  id
  title
  status
  pathway_definition_id
  status_explanation
  patient {
    id
    profile {
      name
    }
  }
  tracks {
    id
    title
  }
  activities {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;
export const PatientProfileFragmentDoc = gql`
    fragment PatientProfile on UserProfile {
  email
  sex
  birth_date
  first_name
  last_name
  name
  phone
  mobile_phone
  preferred_language
  patient_code
  national_registry_number
  identifier {
    system
    value
  }
  address {
    street
    city
    zip
    state
    country
  }
}
    `;
export const PatientFragmentDoc = gql`
    fragment Patient on User {
  id
  profile {
    ...PatientProfile
  }
}
    ${PatientProfileFragmentDoc}`;
export const PatientPathwayFragmentDoc = gql`
    fragment PatientPathway on PatientPathway {
  id
  title
  pathway_definition_id
  status_explanation
  status
  release_id
  stop_date
  complete_date
  total_activities
  active_activities
  failed_activities
  latest_activity_date
  latest_activity_title
  latest_activity_type
}
    `;
export const GeneratedClinicalNoteFragmentDoc = gql`
    fragment GeneratedClinicalNote on GeneratedClinicalNote {
  id
  narratives {
    id
    key
    title
    body
  }
  context {
    key
    value
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  title
  dataPointValueType
  options {
    id
    value
    value_string
    label
  }
  questionType
  userQuestionType
  questionConfig {
    recode_enabled
    use_select
    mandatory
    slider {
      min
      max
      step_value
      display_marks
      min_label
      max_label
      is_value_tooltip_on
      show_min_max_values
    }
    phone {
      default_country
      available_countries
    }
    number {
      range {
        min
        max
        enabled
      }
    }
    multiple_select {
      range {
        min
        max
        enabled
      }
      exclusive_option {
        option_id
        enabled
      }
    }
    date {
      allowed_dates
      include_date_of_response
    }
  }
}
    `;
export const WebhookCallFragmentDoc = gql`
    fragment WebhookCall on WebhookCall {
  id
  webhook_name
  webhook_id
  request {
    method
    endpoint
    headers {
      key
      value
    }
    body
  }
  status
  responses {
    status
    body
    date
  }
  event_type
  created_at
  pathway {
    id
    pathway_definition_id
    patient_id
    start_date
  }
}
    `;
export const ElementFragmentDoc = gql`
    fragment Element on Element {
  id
  parent_id
  name
  status
  type
  activity_type
  start_date
  end_date
  label {
    id
    color
    text
  }
  stakeholders {
    id
    name
  }
  context {
    instance_id
    pathway_id
    track_id
    step_id
    action_id
  }
}
    `;
export const SaveBaselineInfoDocument = gql`
    mutation SaveBaselineInfo($input: SaveBaselineInfoInput!) {
  saveBaselineInfo(input: $input) {
    success
  }
}
    `;
export type SaveBaselineInfoMutationFn = Apollo.MutationFunction<SaveBaselineInfoMutation, SaveBaselineInfoMutationVariables>;

/**
 * __useSaveBaselineInfoMutation__
 *
 * To run a mutation, you first call `useSaveBaselineInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBaselineInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBaselineInfoMutation, { data, loading, error }] = useSaveBaselineInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBaselineInfoMutation(baseOptions?: Apollo.MutationHookOptions<SaveBaselineInfoMutation, SaveBaselineInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBaselineInfoMutation, SaveBaselineInfoMutationVariables>(SaveBaselineInfoDocument, options);
      }
export type SaveBaselineInfoMutationHookResult = ReturnType<typeof useSaveBaselineInfoMutation>;
export type SaveBaselineInfoMutationResult = Apollo.MutationResult<SaveBaselineInfoMutation>;
export type SaveBaselineInfoMutationOptions = Apollo.BaseMutationOptions<SaveBaselineInfoMutation, SaveBaselineInfoMutationVariables>;
export const EvaluateFormRulesDocument = gql`
    mutation EvaluateFormRules($input: EvaluateFormRulesInput!) {
  evaluateFormRules(input: $input) {
    results {
      question_id
      rule_id
      satisfied
    }
  }
}
    `;
export type EvaluateFormRulesMutationFn = Apollo.MutationFunction<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>;

/**
 * __useEvaluateFormRulesMutation__
 *
 * To run a mutation, you first call `useEvaluateFormRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvaluateFormRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [evaluateFormRulesMutation, { data, loading, error }] = useEvaluateFormRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEvaluateFormRulesMutation(baseOptions?: Apollo.MutationHookOptions<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>(EvaluateFormRulesDocument, options);
      }
export type EvaluateFormRulesMutationHookResult = ReturnType<typeof useEvaluateFormRulesMutation>;
export type EvaluateFormRulesMutationResult = Apollo.MutationResult<EvaluateFormRulesMutation>;
export type EvaluateFormRulesMutationOptions = Apollo.BaseMutationOptions<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>;
export const GetActivitiesDocument = gql`
    query GetActivities($filters: FilterActivitiesParams, $pagination: PaginationParams, $sorting: SortingParams) {
  activities(filters: $filters, pagination: $pagination, sorting: $sorting) {
    pagination {
      offset
      count
      total_count
    }
    sorting {
      field
      direction
    }
    activities {
      ...Activity
    }
    metadata {
      stakeholders {
        id
        name
        type
      }
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, options);
      }
export function useGetActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, options);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export type GetActivitiesQueryResult = Apollo.QueryResult<GetActivitiesQuery, GetActivitiesQueryVariables>;
export const OnActivityCompletedDocument = gql`
    subscription OnActivityCompleted($pathway_id: String!) {
  activityCompleted(pathway_id: $pathway_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityCompletedSubscription__
 *
 * To run a query within a React component, call `useOnActivityCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityCompletedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnActivityCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityCompletedSubscription, OnActivityCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityCompletedSubscription, OnActivityCompletedSubscriptionVariables>(OnActivityCompletedDocument, options);
      }
export type OnActivityCompletedSubscriptionHookResult = ReturnType<typeof useOnActivityCompletedSubscription>;
export type OnActivityCompletedSubscriptionResult = Apollo.SubscriptionResult<OnActivityCompletedSubscription>;
export const OnActivityCreatedDocument = gql`
    subscription OnActivityCreated($pathway_id: String!) {
  activityCreated(pathway_id: $pathway_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityCreatedSubscription__
 *
 * To run a query within a React component, call `useOnActivityCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityCreatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnActivityCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityCreatedSubscription, OnActivityCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityCreatedSubscription, OnActivityCreatedSubscriptionVariables>(OnActivityCreatedDocument, options);
      }
export type OnActivityCreatedSubscriptionHookResult = ReturnType<typeof useOnActivityCreatedSubscription>;
export type OnActivityCreatedSubscriptionResult = Apollo.SubscriptionResult<OnActivityCreatedSubscription>;
export const OnActivityExpiredDocument = gql`
    subscription OnActivityExpired($pathway_id: String!) {
  activityExpired(pathway_id: $pathway_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityExpiredSubscription__
 *
 * To run a query within a React component, call `useOnActivityExpiredSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityExpiredSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityExpiredSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnActivityExpiredSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityExpiredSubscription, OnActivityExpiredSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityExpiredSubscription, OnActivityExpiredSubscriptionVariables>(OnActivityExpiredDocument, options);
      }
export type OnActivityExpiredSubscriptionHookResult = ReturnType<typeof useOnActivityExpiredSubscription>;
export type OnActivityExpiredSubscriptionResult = Apollo.SubscriptionResult<OnActivityExpiredSubscription>;
export const OnActivityUpdatedDocument = gql`
    subscription OnActivityUpdated($pathway_id: String!) {
  activityUpdated(pathway_id: $pathway_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnActivityUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityUpdatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnActivityUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityUpdatedSubscription, OnActivityUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityUpdatedSubscription, OnActivityUpdatedSubscriptionVariables>(OnActivityUpdatedDocument, options);
      }
export type OnActivityUpdatedSubscriptionHookResult = ReturnType<typeof useOnActivityUpdatedSubscription>;
export type OnActivityUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnActivityUpdatedSubscription>;
export const PathwayActivitiesDocument = gql`
    query PathwayActivities($pathway_id: String!) {
  pathwayActivities(pathway_id: $pathway_id) {
    success
    activities {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __usePathwayActivitiesQuery__
 *
 * To run a query within a React component, call `usePathwayActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayActivitiesQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function usePathwayActivitiesQuery(baseOptions: Apollo.QueryHookOptions<PathwayActivitiesQuery, PathwayActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayActivitiesQuery, PathwayActivitiesQueryVariables>(PathwayActivitiesDocument, options);
      }
export function usePathwayActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayActivitiesQuery, PathwayActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayActivitiesQuery, PathwayActivitiesQueryVariables>(PathwayActivitiesDocument, options);
        }
export type PathwayActivitiesQueryHookResult = ReturnType<typeof usePathwayActivitiesQuery>;
export type PathwayActivitiesLazyQueryHookResult = ReturnType<typeof usePathwayActivitiesLazyQuery>;
export type PathwayActivitiesQueryResult = Apollo.QueryResult<PathwayActivitiesQuery, PathwayActivitiesQueryVariables>;
export const AddTrackDocument = gql`
    mutation AddTrack($input: AddTrackInput!) {
  addTrack(input: $input) {
    success
  }
}
    `;
export type AddTrackMutationFn = Apollo.MutationFunction<AddTrackMutation, AddTrackMutationVariables>;

/**
 * __useAddTrackMutation__
 *
 * To run a mutation, you first call `useAddTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrackMutation, { data, loading, error }] = useAddTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrackMutation(baseOptions?: Apollo.MutationHookOptions<AddTrackMutation, AddTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrackMutation, AddTrackMutationVariables>(AddTrackDocument, options);
      }
export type AddTrackMutationHookResult = ReturnType<typeof useAddTrackMutation>;
export type AddTrackMutationResult = Apollo.MutationResult<AddTrackMutation>;
export type AddTrackMutationOptions = Apollo.BaseMutationOptions<AddTrackMutation, AddTrackMutationVariables>;
export const GetAdHocTracksDocument = gql`
    query GetAdHocTracks($pathway_id: String!) {
  adHocTracksByPathway(pathway_id: $pathway_id) {
    tracks {
      id
      title
    }
  }
}
    `;

/**
 * __useGetAdHocTracksQuery__
 *
 * To run a query within a React component, call `useGetAdHocTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdHocTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdHocTracksQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetAdHocTracksQuery(baseOptions: Apollo.QueryHookOptions<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>(GetAdHocTracksDocument, options);
      }
export function useGetAdHocTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>(GetAdHocTracksDocument, options);
        }
export type GetAdHocTracksQueryHookResult = ReturnType<typeof useGetAdHocTracksQuery>;
export type GetAdHocTracksLazyQueryHookResult = ReturnType<typeof useGetAdHocTracksLazyQuery>;
export type GetAdHocTracksQueryResult = Apollo.QueryResult<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>;
export const ApiCallDocument = gql`
    query ApiCall($id: String!) {
  apiCall(id: $id) {
    api_call {
      id
      status
      request {
        method
        endpoint
        headers {
          key
          value
        }
        body
      }
      responses {
        status
        body
        date
      }
      title
    }
  }
}
    `;

/**
 * __useApiCallQuery__
 *
 * To run a query within a React component, call `useApiCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiCallQuery(baseOptions: Apollo.QueryHookOptions<ApiCallQuery, ApiCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiCallQuery, ApiCallQueryVariables>(ApiCallDocument, options);
      }
export function useApiCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiCallQuery, ApiCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiCallQuery, ApiCallQueryVariables>(ApiCallDocument, options);
        }
export type ApiCallQueryHookResult = ReturnType<typeof useApiCallQuery>;
export type ApiCallLazyQueryHookResult = ReturnType<typeof useApiCallLazyQuery>;
export type ApiCallQueryResult = Apollo.QueryResult<ApiCallQuery, ApiCallQueryVariables>;
export const GetBaselineInfoDocument = gql`
    query GetBaselineInfo($pathway_id: String!) {
  baselineInfo(pathway_id: $pathway_id) {
    baselineDataPoints {
      value
      definition {
        id
        title
        category
        key
        valueType
        possibleValues {
          label
          value
        }
        unit
        range {
          min
          max
        }
      }
    }
  }
}
    `;

/**
 * __useGetBaselineInfoQuery__
 *
 * To run a query within a React component, call `useGetBaselineInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaselineInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaselineInfoQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetBaselineInfoQuery(baseOptions: Apollo.QueryHookOptions<GetBaselineInfoQuery, GetBaselineInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaselineInfoQuery, GetBaselineInfoQueryVariables>(GetBaselineInfoDocument, options);
      }
export function useGetBaselineInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaselineInfoQuery, GetBaselineInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaselineInfoQuery, GetBaselineInfoQueryVariables>(GetBaselineInfoDocument, options);
        }
export type GetBaselineInfoQueryHookResult = ReturnType<typeof useGetBaselineInfoQuery>;
export type GetBaselineInfoLazyQueryHookResult = ReturnType<typeof useGetBaselineInfoLazyQuery>;
export type GetBaselineInfoQueryResult = Apollo.QueryResult<GetBaselineInfoQuery, GetBaselineInfoQueryVariables>;
export const GetActionCalculationIdDocument = gql`
    query GetActionCalculationId($id: String!) {
  calculationAction(id: $id) {
    calculationId
  }
}
    `;

/**
 * __useGetActionCalculationIdQuery__
 *
 * To run a query within a React component, call `useGetActionCalculationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionCalculationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionCalculationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionCalculationIdQuery(baseOptions: Apollo.QueryHookOptions<GetActionCalculationIdQuery, GetActionCalculationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionCalculationIdQuery, GetActionCalculationIdQueryVariables>(GetActionCalculationIdDocument, options);
      }
export function useGetActionCalculationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionCalculationIdQuery, GetActionCalculationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionCalculationIdQuery, GetActionCalculationIdQueryVariables>(GetActionCalculationIdDocument, options);
        }
export type GetActionCalculationIdQueryHookResult = ReturnType<typeof useGetActionCalculationIdQuery>;
export type GetActionCalculationIdLazyQueryHookResult = ReturnType<typeof useGetActionCalculationIdLazyQuery>;
export type GetActionCalculationIdQueryResult = Apollo.QueryResult<GetActionCalculationIdQuery, GetActionCalculationIdQueryVariables>;
export const GetCalculationResultsDocument = gql`
    query GetCalculationResults($pathway_id: String!, $activity_id: String!) {
  calculationResults(pathway_id: $pathway_id, activity_id: $activity_id) {
    result {
      status
      subresult_id
      unit
      value
      value_type
    }
  }
}
    `;

/**
 * __useGetCalculationResultsQuery__
 *
 * To run a query within a React component, call `useGetCalculationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalculationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalculationResultsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      activity_id: // value for 'activity_id'
 *   },
 * });
 */
export function useGetCalculationResultsQuery(baseOptions: Apollo.QueryHookOptions<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>(GetCalculationResultsDocument, options);
      }
export function useGetCalculationResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>(GetCalculationResultsDocument, options);
        }
export type GetCalculationResultsQueryHookResult = ReturnType<typeof useGetCalculationResultsQuery>;
export type GetCalculationResultsLazyQueryHookResult = ReturnType<typeof useGetCalculationResultsLazyQuery>;
export type GetCalculationResultsQueryResult = Apollo.QueryResult<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>;
export const GetChecklistDocument = gql`
    query GetChecklist($id: String!) {
  checklist(id: $id) {
    checklist {
      title
      items
    }
  }
}
    `;

/**
 * __useGetChecklistQuery__
 *
 * To run a query within a React component, call `useGetChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChecklistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChecklistQuery(baseOptions: Apollo.QueryHookOptions<GetChecklistQuery, GetChecklistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChecklistQuery, GetChecklistQueryVariables>(GetChecklistDocument, options);
      }
export function useGetChecklistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChecklistQuery, GetChecklistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChecklistQuery, GetChecklistQueryVariables>(GetChecklistDocument, options);
        }
export type GetChecklistQueryHookResult = ReturnType<typeof useGetChecklistQuery>;
export type GetChecklistLazyQueryHookResult = ReturnType<typeof useGetChecklistLazyQuery>;
export type GetChecklistQueryResult = Apollo.QueryResult<GetChecklistQuery, GetChecklistQueryVariables>;
export const SubmitChecklistDocument = gql`
    mutation SubmitChecklist($input: SubmitChecklistInput!) {
  submitChecklist(input: $input) {
    activity {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;
export type SubmitChecklistMutationFn = Apollo.MutationFunction<SubmitChecklistMutation, SubmitChecklistMutationVariables>;

/**
 * __useSubmitChecklistMutation__
 *
 * To run a mutation, you first call `useSubmitChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChecklistMutation, { data, loading, error }] = useSubmitChecklistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitChecklistMutation(baseOptions?: Apollo.MutationHookOptions<SubmitChecklistMutation, SubmitChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitChecklistMutation, SubmitChecklistMutationVariables>(SubmitChecklistDocument, options);
      }
export type SubmitChecklistMutationHookResult = ReturnType<typeof useSubmitChecklistMutation>;
export type SubmitChecklistMutationResult = Apollo.MutationResult<SubmitChecklistMutation>;
export type SubmitChecklistMutationOptions = Apollo.BaseMutationOptions<SubmitChecklistMutation, SubmitChecklistMutationVariables>;
export const GetGeneratedClinicalNoteDocument = gql`
    query GetGeneratedClinicalNote($id: String!) {
  clinicalNote(id: $id) {
    clinical_note {
      ...GeneratedClinicalNote
    }
  }
}
    ${GeneratedClinicalNoteFragmentDoc}`;

/**
 * __useGetGeneratedClinicalNoteQuery__
 *
 * To run a query within a React component, call `useGetGeneratedClinicalNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedClinicalNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedClinicalNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneratedClinicalNoteQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>(GetGeneratedClinicalNoteDocument, options);
      }
export function useGetGeneratedClinicalNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>(GetGeneratedClinicalNoteDocument, options);
        }
export type GetGeneratedClinicalNoteQueryHookResult = ReturnType<typeof useGetGeneratedClinicalNoteQuery>;
export type GetGeneratedClinicalNoteLazyQueryHookResult = ReturnType<typeof useGetGeneratedClinicalNoteLazyQuery>;
export type GetGeneratedClinicalNoteQueryResult = Apollo.QueryResult<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>;
export const CreatePatientDocument = gql`
    mutation CreatePatient($input: CreatePatientInput!) {
  createPatient(input: $input) {
    patient {
      ...Patient
    }
  }
}
    ${PatientFragmentDoc}`;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, options);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const DeletePathwayDocument = gql`
    mutation DeletePathway($input: DeletePathwayInput!) {
  deletePathway(input: $input) {
    success
  }
}
    `;
export type DeletePathwayMutationFn = Apollo.MutationFunction<DeletePathwayMutation, DeletePathwayMutationVariables>;

/**
 * __useDeletePathwayMutation__
 *
 * To run a mutation, you first call `useDeletePathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePathwayMutation, { data, loading, error }] = useDeletePathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePathwayMutation(baseOptions?: Apollo.MutationHookOptions<DeletePathwayMutation, DeletePathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePathwayMutation, DeletePathwayMutationVariables>(DeletePathwayDocument, options);
      }
export type DeletePathwayMutationHookResult = ReturnType<typeof useDeletePathwayMutation>;
export type DeletePathwayMutationResult = Apollo.MutationResult<DeletePathwayMutation>;
export type DeletePathwayMutationOptions = Apollo.BaseMutationOptions<DeletePathwayMutation, DeletePathwayMutationVariables>;
export const DeletePatientDocument = gql`
    mutation DeletePatient($input: DeletePatientInput!) {
  deletePatient(input: $input) {
    success
  }
}
    `;
export type DeletePatientMutationFn = Apollo.MutationFunction<DeletePatientMutation, DeletePatientMutationVariables>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientMutation, DeletePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientMutation, DeletePatientMutationVariables>(DeletePatientDocument, options);
      }
export type DeletePatientMutationHookResult = ReturnType<typeof useDeletePatientMutation>;
export type DeletePatientMutationResult = Apollo.MutationResult<DeletePatientMutation>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<DeletePatientMutation, DeletePatientMutationVariables>;
export const GetEmrReportDocument = gql`
    query GetEmrReport($id: String!) {
  emrReport(id: $id) {
    report {
      id
      message_html
      metadata {
        id
        label
        value
      }
    }
  }
}
    `;

/**
 * __useGetEmrReportQuery__
 *
 * To run a query within a React component, call `useGetEmrReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmrReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmrReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmrReportQuery(baseOptions: Apollo.QueryHookOptions<GetEmrReportQuery, GetEmrReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmrReportQuery, GetEmrReportQueryVariables>(GetEmrReportDocument, options);
      }
export function useGetEmrReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmrReportQuery, GetEmrReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmrReportQuery, GetEmrReportQueryVariables>(GetEmrReportDocument, options);
        }
export type GetEmrReportQueryHookResult = ReturnType<typeof useGetEmrReportQuery>;
export type GetEmrReportLazyQueryHookResult = ReturnType<typeof useGetEmrReportLazyQuery>;
export type GetEmrReportQueryResult = Apollo.QueryResult<GetEmrReportQuery, GetEmrReportQueryVariables>;
export const GetExtensionActivityRecordDocument = gql`
    query GetExtensionActivityRecord($id: String!) {
  extensionActivityRecord(id: $id) {
    record {
      id
      activity_id
      pathway_id
      plugin_key
      plugin_action_key
      fields {
        id
        type
        label
        value
      }
      date
      settings {
        value
        label
        key
      }
      data_points {
        label
        value
      }
    }
  }
}
    `;

/**
 * __useGetExtensionActivityRecordQuery__
 *
 * To run a query within a React component, call `useGetExtensionActivityRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionActivityRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionActivityRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExtensionActivityRecordQuery(baseOptions: Apollo.QueryHookOptions<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>(GetExtensionActivityRecordDocument, options);
      }
export function useGetExtensionActivityRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>(GetExtensionActivityRecordDocument, options);
        }
export type GetExtensionActivityRecordQueryHookResult = ReturnType<typeof useGetExtensionActivityRecordQuery>;
export type GetExtensionActivityRecordLazyQueryHookResult = ReturnType<typeof useGetExtensionActivityRecordLazyQuery>;
export type GetExtensionActivityRecordQueryResult = Apollo.QueryResult<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>;
export const GetFormResponseDocument = gql`
    query GetFormResponse($pathway_id: String!, $activity_id: String!) {
  formResponse(pathway_id: $pathway_id, activity_id: $activity_id) {
    response {
      answers {
        question_id
        value
        value_type
      }
    }
  }
}
    `;

/**
 * __useGetFormResponseQuery__
 *
 * To run a query within a React component, call `useGetFormResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormResponseQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      activity_id: // value for 'activity_id'
 *   },
 * });
 */
export function useGetFormResponseQuery(baseOptions: Apollo.QueryHookOptions<GetFormResponseQuery, GetFormResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormResponseQuery, GetFormResponseQueryVariables>(GetFormResponseDocument, options);
      }
export function useGetFormResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormResponseQuery, GetFormResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormResponseQuery, GetFormResponseQueryVariables>(GetFormResponseDocument, options);
        }
export type GetFormResponseQueryHookResult = ReturnType<typeof useGetFormResponseQuery>;
export type GetFormResponseLazyQueryHookResult = ReturnType<typeof useGetFormResponseLazyQuery>;
export type GetFormResponseQueryResult = Apollo.QueryResult<GetFormResponseQuery, GetFormResponseQueryVariables>;
export const SubmitFormResponseDocument = gql`
    mutation SubmitFormResponse($input: SubmitFormResponseInput!) {
  submitFormResponse(input: $input) {
    activity {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;
export type SubmitFormResponseMutationFn = Apollo.MutationFunction<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>;

/**
 * __useSubmitFormResponseMutation__
 *
 * To run a mutation, you first call `useSubmitFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFormResponseMutation, { data, loading, error }] = useSubmitFormResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFormResponseMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>(SubmitFormResponseDocument, options);
      }
export type SubmitFormResponseMutationHookResult = ReturnType<typeof useSubmitFormResponseMutation>;
export type SubmitFormResponseMutationResult = Apollo.MutationResult<SubmitFormResponseMutation>;
export type SubmitFormResponseMutationOptions = Apollo.BaseMutationOptions<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>;
export const GetFormDocument = gql`
    query GetForm($id: String!, $careflowId: String!) {
  form(id: $id) {
    form {
      id
      title
      trademark
      previous_answers(pathway_id: $careflowId) {
        activity_id
        date
        answers {
          question_id
          value
          value_type
        }
      }
      questions {
        ...Question
      }
    }
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useGetFormQuery__
 *
 * To run a query within a React component, call `useGetFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *      careflowId: // value for 'careflowId'
 *   },
 * });
 */
export function useGetFormQuery(baseOptions: Apollo.QueryHookOptions<GetFormQuery, GetFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormQuery, GetFormQueryVariables>(GetFormDocument, options);
      }
export function useGetFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormQuery, GetFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormQuery, GetFormQueryVariables>(GetFormDocument, options);
        }
export type GetFormQueryHookResult = ReturnType<typeof useGetFormQuery>;
export type GetFormLazyQueryHookResult = ReturnType<typeof useGetFormLazyQuery>;
export type GetFormQueryResult = Apollo.QueryResult<GetFormQuery, GetFormQueryVariables>;
export const GetHostedPagesLinkDocument = gql`
    query GetHostedPagesLink($pathwayId: String!, $stakeholderId: String!) {
  hostedPagesLink(pathway_id: $pathwayId, stakeholder_id: $stakeholderId) {
    code
    success
    hosted_pages_link {
      url
    }
  }
}
    `;

/**
 * __useGetHostedPagesLinkQuery__
 *
 * To run a query within a React component, call `useGetHostedPagesLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostedPagesLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostedPagesLinkQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *      stakeholderId: // value for 'stakeholderId'
 *   },
 * });
 */
export function useGetHostedPagesLinkQuery(baseOptions: Apollo.QueryHookOptions<GetHostedPagesLinkQuery, GetHostedPagesLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostedPagesLinkQuery, GetHostedPagesLinkQueryVariables>(GetHostedPagesLinkDocument, options);
      }
export function useGetHostedPagesLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostedPagesLinkQuery, GetHostedPagesLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostedPagesLinkQuery, GetHostedPagesLinkQueryVariables>(GetHostedPagesLinkDocument, options);
        }
export type GetHostedPagesLinkQueryHookResult = ReturnType<typeof useGetHostedPagesLinkQuery>;
export type GetHostedPagesLinkLazyQueryHookResult = ReturnType<typeof useGetHostedPagesLinkLazyQuery>;
export type GetHostedPagesLinkQueryResult = Apollo.QueryResult<GetHostedPagesLinkQuery, GetHostedPagesLinkQueryVariables>;
export const GetOrchestrationFactsDocument = gql`
    query GetOrchestrationFacts($filters: PathwayFactsFilters!, $pagination: PaginationParams, $sorting: SortingParams) {
  pathwayFacts(filters: $filters, pagination: $pagination, sorting: $sorting) {
    code
    success
    facts {
      date
      level
      pathway_id
      content
    }
    pagination {
      offset
      count
      total_count
    }
    sorting {
      field
      direction
    }
  }
}
    `;

/**
 * __useGetOrchestrationFactsQuery__
 *
 * To run a query within a React component, call `useGetOrchestrationFactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrchestrationFactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrchestrationFactsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrchestrationFactsQuery(baseOptions: Apollo.QueryHookOptions<GetOrchestrationFactsQuery, GetOrchestrationFactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrchestrationFactsQuery, GetOrchestrationFactsQueryVariables>(GetOrchestrationFactsDocument, options);
      }
export function useGetOrchestrationFactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrchestrationFactsQuery, GetOrchestrationFactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrchestrationFactsQuery, GetOrchestrationFactsQueryVariables>(GetOrchestrationFactsDocument, options);
        }
export type GetOrchestrationFactsQueryHookResult = ReturnType<typeof useGetOrchestrationFactsQuery>;
export type GetOrchestrationFactsLazyQueryHookResult = ReturnType<typeof useGetOrchestrationFactsLazyQuery>;
export type GetOrchestrationFactsQueryResult = Apollo.QueryResult<GetOrchestrationFactsQuery, GetOrchestrationFactsQueryVariables>;
export const GetApiCallDocument = gql`
    query GetApiCall($id: String!) {
  apiCall(id: $id) {
    code
    success
    api_call {
      title
      created_at
      status
      id
      request {
        body
        method
        endpoint
        headers {
          value
          key
        }
      }
      responses {
        status
        date
        body
      }
    }
  }
}
    `;

/**
 * __useGetApiCallQuery__
 *
 * To run a query within a React component, call `useGetApiCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApiCallQuery(baseOptions: Apollo.QueryHookOptions<GetApiCallQuery, GetApiCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiCallQuery, GetApiCallQueryVariables>(GetApiCallDocument, options);
      }
export function useGetApiCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiCallQuery, GetApiCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiCallQuery, GetApiCallQueryVariables>(GetApiCallDocument, options);
        }
export type GetApiCallQueryHookResult = ReturnType<typeof useGetApiCallQuery>;
export type GetApiCallLazyQueryHookResult = ReturnType<typeof useGetApiCallLazyQuery>;
export type GetApiCallQueryResult = Apollo.QueryResult<GetApiCallQuery, GetApiCallQueryVariables>;
export const GetApiCallsDocument = gql`
    query GetApiCalls($pathway_id: String!) {
  apiCalls(pathway_id: $pathway_id) {
    code
    success
    api_calls {
      title
      created_at
      status
      id
      request {
        body
        method
        endpoint
        headers {
          value
          key
        }
      }
      responses {
        status
        date
        body
      }
    }
  }
}
    `;

/**
 * __useGetApiCallsQuery__
 *
 * To run a query within a React component, call `useGetApiCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiCallsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetApiCallsQuery(baseOptions: Apollo.QueryHookOptions<GetApiCallsQuery, GetApiCallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiCallsQuery, GetApiCallsQueryVariables>(GetApiCallsDocument, options);
      }
export function useGetApiCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiCallsQuery, GetApiCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiCallsQuery, GetApiCallsQueryVariables>(GetApiCallsDocument, options);
        }
export type GetApiCallsQueryHookResult = ReturnType<typeof useGetApiCallsQuery>;
export type GetApiCallsLazyQueryHookResult = ReturnType<typeof useGetApiCallsLazyQuery>;
export type GetApiCallsQueryResult = Apollo.QueryResult<GetApiCallsQuery, GetApiCallsQueryVariables>;
export const OnApiCallCreatedDocument = gql`
    subscription OnApiCallCreated($pathway_id: String!) {
  apiCallCreated(pathway_id: $pathway_id) {
    status
    created_at
    status
    id
    title
    request {
      body
      method
      endpoint
      headers {
        value
        key
      }
    }
    responses {
      status
      date
      body
    }
  }
}
    `;

/**
 * __useOnApiCallCreatedSubscription__
 *
 * To run a query within a React component, call `useOnApiCallCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnApiCallCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnApiCallCreatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnApiCallCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnApiCallCreatedSubscription, OnApiCallCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnApiCallCreatedSubscription, OnApiCallCreatedSubscriptionVariables>(OnApiCallCreatedDocument, options);
      }
export type OnApiCallCreatedSubscriptionHookResult = ReturnType<typeof useOnApiCallCreatedSubscription>;
export type OnApiCallCreatedSubscriptionResult = Apollo.SubscriptionResult<OnApiCallCreatedSubscription>;
export const OnApiCallUpdatedDocument = gql`
    subscription OnApiCallUpdated($pathway_id: String!) {
  apiCallUpdated(pathway_id: $pathway_id) {
    status
    created_at
    status
    id
    title
    request {
      body
      method
      endpoint
      headers {
        value
        key
      }
    }
    responses {
      status
      date
      body
    }
  }
}
    `;

/**
 * __useOnApiCallUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnApiCallUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnApiCallUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnApiCallUpdatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnApiCallUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnApiCallUpdatedSubscription, OnApiCallUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnApiCallUpdatedSubscription, OnApiCallUpdatedSubscriptionVariables>(OnApiCallUpdatedDocument, options);
      }
export type OnApiCallUpdatedSubscriptionHookResult = ReturnType<typeof useOnApiCallUpdatedSubscription>;
export type OnApiCallUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnApiCallUpdatedSubscription>;
export const GetPathwayWebhookCallDocument = gql`
    query GetPathwayWebhookCall($webhook_call_id: String!) {
  webhookCall(webhook_call_id: $webhook_call_id) {
    webhook_call {
      id
      event_type
      webhook_id
      webhook_name
      responses {
        date
        status
        body
      }
      status
      created_at
      request {
        headers {
          key
          value
        }
        body
        method
        endpoint
      }
      pathway {
        id
        pathway_definition_id
        start_date
      }
    }
  }
}
    `;

/**
 * __useGetPathwayWebhookCallQuery__
 *
 * To run a query within a React component, call `useGetPathwayWebhookCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayWebhookCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayWebhookCallQuery({
 *   variables: {
 *      webhook_call_id: // value for 'webhook_call_id'
 *   },
 * });
 */
export function useGetPathwayWebhookCallQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayWebhookCallQuery, GetPathwayWebhookCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayWebhookCallQuery, GetPathwayWebhookCallQueryVariables>(GetPathwayWebhookCallDocument, options);
      }
export function useGetPathwayWebhookCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayWebhookCallQuery, GetPathwayWebhookCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayWebhookCallQuery, GetPathwayWebhookCallQueryVariables>(GetPathwayWebhookCallDocument, options);
        }
export type GetPathwayWebhookCallQueryHookResult = ReturnType<typeof useGetPathwayWebhookCallQuery>;
export type GetPathwayWebhookCallLazyQueryHookResult = ReturnType<typeof useGetPathwayWebhookCallLazyQuery>;
export type GetPathwayWebhookCallQueryResult = Apollo.QueryResult<GetPathwayWebhookCallQuery, GetPathwayWebhookCallQueryVariables>;
export const GetPathwayWebhookCallsDocument = gql`
    query GetPathwayWebhookCalls($pathway_id: String!) {
  webhookCalls(pathway_id: $pathway_id) {
    webhook_calls {
      ...WebhookCall
    }
  }
}
    ${WebhookCallFragmentDoc}`;

/**
 * __useGetPathwayWebhookCallsQuery__
 *
 * To run a query within a React component, call `useGetPathwayWebhookCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayWebhookCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayWebhookCallsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetPathwayWebhookCallsQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayWebhookCallsQuery, GetPathwayWebhookCallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayWebhookCallsQuery, GetPathwayWebhookCallsQueryVariables>(GetPathwayWebhookCallsDocument, options);
      }
export function useGetPathwayWebhookCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayWebhookCallsQuery, GetPathwayWebhookCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayWebhookCallsQuery, GetPathwayWebhookCallsQueryVariables>(GetPathwayWebhookCallsDocument, options);
        }
export type GetPathwayWebhookCallsQueryHookResult = ReturnType<typeof useGetPathwayWebhookCallsQuery>;
export type GetPathwayWebhookCallsLazyQueryHookResult = ReturnType<typeof useGetPathwayWebhookCallsLazyQuery>;
export type GetPathwayWebhookCallsQueryResult = Apollo.QueryResult<GetPathwayWebhookCallsQuery, GetPathwayWebhookCallsQueryVariables>;
export const OnWebhookCallCreatedDocument = gql`
    subscription OnWebhookCallCreated($pathway_id: String!) {
  webhookCallCreated(pathway_id: $pathway_id) {
    ...WebhookCall
  }
}
    ${WebhookCallFragmentDoc}`;

/**
 * __useOnWebhookCallCreatedSubscription__
 *
 * To run a query within a React component, call `useOnWebhookCallCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWebhookCallCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWebhookCallCreatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnWebhookCallCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWebhookCallCreatedSubscription, OnWebhookCallCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWebhookCallCreatedSubscription, OnWebhookCallCreatedSubscriptionVariables>(OnWebhookCallCreatedDocument, options);
      }
export type OnWebhookCallCreatedSubscriptionHookResult = ReturnType<typeof useOnWebhookCallCreatedSubscription>;
export type OnWebhookCallCreatedSubscriptionResult = Apollo.SubscriptionResult<OnWebhookCallCreatedSubscription>;
export const OnWebhookCallUpdatedDocument = gql`
    subscription OnWebhookCallUpdated($pathway_id: String!) {
  webhookCallUpdated(pathway_id: $pathway_id) {
    ...WebhookCall
  }
}
    ${WebhookCallFragmentDoc}`;

/**
 * __useOnWebhookCallUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnWebhookCallUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWebhookCallUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWebhookCallUpdatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnWebhookCallUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWebhookCallUpdatedSubscription, OnWebhookCallUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWebhookCallUpdatedSubscription, OnWebhookCallUpdatedSubscriptionVariables>(OnWebhookCallUpdatedDocument, options);
      }
export type OnWebhookCallUpdatedSubscriptionHookResult = ReturnType<typeof useOnWebhookCallUpdatedSubscription>;
export type OnWebhookCallUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnWebhookCallUpdatedSubscription>;
export const GetMessageDocument = gql`
    query GetMessage($id: String!) {
  message(id: $id) {
    message {
      id
      body
      subject
      format
      attachments {
        id
        name
        type
        url
      }
    }
  }
}
    `;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageQuery(baseOptions: Apollo.QueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
      }
export function useGetMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
        }
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<typeof useGetMessageLazyQuery>;
export type GetMessageQueryResult = Apollo.QueryResult<GetMessageQuery, GetMessageQueryVariables>;
export const MarkMessageAsReadDocument = gql`
    mutation MarkMessageAsRead($input: MarkMessageAsReadInput!) {
  markMessageAsRead(input: $input) {
    activity {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>(MarkMessageAsReadDocument, options);
      }
export type MarkMessageAsReadMutationHookResult = ReturnType<typeof useMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationResult = Apollo.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;
export const GetOrchestrationFactsFromPromptDocument = gql`
    query GetOrchestrationFactsFromPrompt($pathway_id: String!, $prompt: String!) {
  getOrchestrationFactsFromPrompt(pathway_id: $pathway_id, prompt: $prompt) {
    response
    __typename
    success
  }
}
    `;

/**
 * __useGetOrchestrationFactsFromPromptQuery__
 *
 * To run a query within a React component, call `useGetOrchestrationFactsFromPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrchestrationFactsFromPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrchestrationFactsFromPromptQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      prompt: // value for 'prompt'
 *   },
 * });
 */
export function useGetOrchestrationFactsFromPromptQuery(baseOptions: Apollo.QueryHookOptions<GetOrchestrationFactsFromPromptQuery, GetOrchestrationFactsFromPromptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrchestrationFactsFromPromptQuery, GetOrchestrationFactsFromPromptQueryVariables>(GetOrchestrationFactsFromPromptDocument, options);
      }
export function useGetOrchestrationFactsFromPromptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrchestrationFactsFromPromptQuery, GetOrchestrationFactsFromPromptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrchestrationFactsFromPromptQuery, GetOrchestrationFactsFromPromptQueryVariables>(GetOrchestrationFactsFromPromptDocument, options);
        }
export type GetOrchestrationFactsFromPromptQueryHookResult = ReturnType<typeof useGetOrchestrationFactsFromPromptQuery>;
export type GetOrchestrationFactsFromPromptLazyQueryHookResult = ReturnType<typeof useGetOrchestrationFactsFromPromptLazyQuery>;
export type GetOrchestrationFactsFromPromptQueryResult = Apollo.QueryResult<GetOrchestrationFactsFromPromptQuery, GetOrchestrationFactsFromPromptQueryVariables>;
export const GetPathwayDocument = gql`
    query GetPathway($pathway_id: String!) {
  pathway(id: $pathway_id) {
    pathway {
      ...Pathway
    }
  }
}
    ${PathwayFragmentDoc}`;

/**
 * __useGetPathwayQuery__
 *
 * To run a query within a React component, call `useGetPathwayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetPathwayQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayQuery, GetPathwayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayQuery, GetPathwayQueryVariables>(GetPathwayDocument, options);
      }
export function useGetPathwayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayQuery, GetPathwayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayQuery, GetPathwayQueryVariables>(GetPathwayDocument, options);
        }
export type GetPathwayQueryHookResult = ReturnType<typeof useGetPathwayQuery>;
export type GetPathwayLazyQueryHookResult = ReturnType<typeof useGetPathwayLazyQuery>;
export type GetPathwayQueryResult = Apollo.QueryResult<GetPathwayQuery, GetPathwayQueryVariables>;
export const OnPathwayUpdatedDocument = gql`
    subscription OnPathwayUpdated($pathway_id: ID!) {
  pathwayUpdated(id: $pathway_id) {
    ...Pathway
  }
}
    ${PathwayFragmentDoc}`;

/**
 * __useOnPathwayUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnPathwayUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPathwayUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPathwayUpdatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useOnPathwayUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPathwayUpdatedSubscription, OnPathwayUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPathwayUpdatedSubscription, OnPathwayUpdatedSubscriptionVariables>(OnPathwayUpdatedDocument, options);
      }
export type OnPathwayUpdatedSubscriptionHookResult = ReturnType<typeof useOnPathwayUpdatedSubscription>;
export type OnPathwayUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnPathwayUpdatedSubscription>;
export const OnElementCompletedDocument = gql`
    subscription OnElementCompleted($pathway_id: String!, $element_type: ElementType) {
  elementCompleted(pathway_id: $pathway_id, element_type: $element_type) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementCompletedSubscription__
 *
 * To run a query within a React component, call `useOnElementCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementCompletedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      element_type: // value for 'element_type'
 *   },
 * });
 */
export function useOnElementCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementCompletedSubscription, OnElementCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementCompletedSubscription, OnElementCompletedSubscriptionVariables>(OnElementCompletedDocument, options);
      }
export type OnElementCompletedSubscriptionHookResult = ReturnType<typeof useOnElementCompletedSubscription>;
export type OnElementCompletedSubscriptionResult = Apollo.SubscriptionResult<OnElementCompletedSubscription>;
export const OnElementCreatedDocument = gql`
    subscription OnElementCreated($pathway_id: String!, $element_type: ElementType) {
  elementCreated(pathway_id: $pathway_id, element_type: $element_type) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementCreatedSubscription__
 *
 * To run a query within a React component, call `useOnElementCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementCreatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      element_type: // value for 'element_type'
 *   },
 * });
 */
export function useOnElementCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementCreatedSubscription, OnElementCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementCreatedSubscription, OnElementCreatedSubscriptionVariables>(OnElementCreatedDocument, options);
      }
export type OnElementCreatedSubscriptionHookResult = ReturnType<typeof useOnElementCreatedSubscription>;
export type OnElementCreatedSubscriptionResult = Apollo.SubscriptionResult<OnElementCreatedSubscription>;
export const OnElementUpdatedDocument = gql`
    subscription OnElementUpdated($pathway_id: String!, $element_type: ElementType) {
  elementUpdated(pathway_id: $pathway_id, element_type: $element_type) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnElementUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementUpdatedSubscription({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      element_type: // value for 'element_type'
 *   },
 * });
 */
export function useOnElementUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementUpdatedSubscription, OnElementUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementUpdatedSubscription, OnElementUpdatedSubscriptionVariables>(OnElementUpdatedDocument, options);
      }
export type OnElementUpdatedSubscriptionHookResult = ReturnType<typeof useOnElementUpdatedSubscription>;
export type OnElementUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnElementUpdatedSubscription>;
export const PathwayElementsDocument = gql`
    query PathwayElements($pathway_id: String!) {
  pathwayElements(pathway_id: $pathway_id) {
    elements {
      ...Element
    }
  }
}
    ${ElementFragmentDoc}`;

/**
 * __usePathwayElementsQuery__
 *
 * To run a query within a React component, call `usePathwayElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayElementsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function usePathwayElementsQuery(baseOptions: Apollo.QueryHookOptions<PathwayElementsQuery, PathwayElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayElementsQuery, PathwayElementsQueryVariables>(PathwayElementsDocument, options);
      }
export function usePathwayElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayElementsQuery, PathwayElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayElementsQuery, PathwayElementsQueryVariables>(PathwayElementsDocument, options);
        }
export type PathwayElementsQueryHookResult = ReturnType<typeof usePathwayElementsQuery>;
export type PathwayElementsLazyQueryHookResult = ReturnType<typeof usePathwayElementsLazyQuery>;
export type PathwayElementsQueryResult = Apollo.QueryResult<PathwayElementsQuery, PathwayElementsQueryVariables>;
export const PathwayStepActivitiesDocument = gql`
    query PathwayStepActivities($pathway_id: String!, $step_id: String!) {
  pathwayStepActivities(pathway_id: $pathway_id, step_id: $step_id) {
    success
    activities {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __usePathwayStepActivitiesQuery__
 *
 * To run a query within a React component, call `usePathwayStepActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayStepActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayStepActivitiesQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *      step_id: // value for 'step_id'
 *   },
 * });
 */
export function usePathwayStepActivitiesQuery(baseOptions: Apollo.QueryHookOptions<PathwayStepActivitiesQuery, PathwayStepActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayStepActivitiesQuery, PathwayStepActivitiesQueryVariables>(PathwayStepActivitiesDocument, options);
      }
export function usePathwayStepActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayStepActivitiesQuery, PathwayStepActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayStepActivitiesQuery, PathwayStepActivitiesQueryVariables>(PathwayStepActivitiesDocument, options);
        }
export type PathwayStepActivitiesQueryHookResult = ReturnType<typeof usePathwayStepActivitiesQuery>;
export type PathwayStepActivitiesLazyQueryHookResult = ReturnType<typeof usePathwayStepActivitiesLazyQuery>;
export type PathwayStepActivitiesQueryResult = Apollo.QueryResult<PathwayStepActivitiesQuery, PathwayStepActivitiesQueryVariables>;
export const GetPatientDocument = gql`
    query GetPatient($patient_id: String!) {
  patient(id: $patient_id) {
    patient {
      ...Patient
    }
  }
}
    ${PatientFragmentDoc}`;

/**
 * __useGetPatientQuery__
 *
 * To run a query within a React component, call `useGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientQuery({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *   },
 * });
 */
export function useGetPatientQuery(baseOptions: Apollo.QueryHookOptions<GetPatientQuery, GetPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options);
      }
export function useGetPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientQuery, GetPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options);
        }
export type GetPatientQueryHookResult = ReturnType<typeof useGetPatientQuery>;
export type GetPatientLazyQueryHookResult = ReturnType<typeof useGetPatientLazyQuery>;
export type GetPatientQueryResult = Apollo.QueryResult<GetPatientQuery, GetPatientQueryVariables>;
export const GetPatientDemographicsQueryConfigurationDocument = gql`
    query GetPatientDemographicsQueryConfiguration {
  patientDemographicsQueryConfiguration {
    is_enabled
    input_box_text
  }
}
    `;

/**
 * __useGetPatientDemographicsQueryConfigurationQuery__
 *
 * To run a query within a React component, call `useGetPatientDemographicsQueryConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDemographicsQueryConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDemographicsQueryConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientDemographicsQueryConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientDemographicsQueryConfigurationQuery, GetPatientDemographicsQueryConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientDemographicsQueryConfigurationQuery, GetPatientDemographicsQueryConfigurationQueryVariables>(GetPatientDemographicsQueryConfigurationDocument, options);
      }
export function useGetPatientDemographicsQueryConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientDemographicsQueryConfigurationQuery, GetPatientDemographicsQueryConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientDemographicsQueryConfigurationQuery, GetPatientDemographicsQueryConfigurationQueryVariables>(GetPatientDemographicsQueryConfigurationDocument, options);
        }
export type GetPatientDemographicsQueryConfigurationQueryHookResult = ReturnType<typeof useGetPatientDemographicsQueryConfigurationQuery>;
export type GetPatientDemographicsQueryConfigurationLazyQueryHookResult = ReturnType<typeof useGetPatientDemographicsQueryConfigurationLazyQuery>;
export type GetPatientDemographicsQueryConfigurationQueryResult = Apollo.QueryResult<GetPatientDemographicsQueryConfigurationQuery, GetPatientDemographicsQueryConfigurationQueryVariables>;
export const RequestPatientDemographicsDocument = gql`
    mutation RequestPatientDemographics($input: PatientDemographicsInput!) {
  requestPatientDemographics(input: $input) {
    query_id
    total
    status
    entry {
      ...PatientProfile
    }
  }
}
    ${PatientProfileFragmentDoc}`;
export type RequestPatientDemographicsMutationFn = Apollo.MutationFunction<RequestPatientDemographicsMutation, RequestPatientDemographicsMutationVariables>;

/**
 * __useRequestPatientDemographicsMutation__
 *
 * To run a mutation, you first call `useRequestPatientDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPatientDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPatientDemographicsMutation, { data, loading, error }] = useRequestPatientDemographicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPatientDemographicsMutation(baseOptions?: Apollo.MutationHookOptions<RequestPatientDemographicsMutation, RequestPatientDemographicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPatientDemographicsMutation, RequestPatientDemographicsMutationVariables>(RequestPatientDemographicsDocument, options);
      }
export type RequestPatientDemographicsMutationHookResult = ReturnType<typeof useRequestPatientDemographicsMutation>;
export type RequestPatientDemographicsMutationResult = Apollo.MutationResult<RequestPatientDemographicsMutation>;
export type RequestPatientDemographicsMutationOptions = Apollo.BaseMutationOptions<RequestPatientDemographicsMutation, RequestPatientDemographicsMutationVariables>;
export const UpdatePatientDemographicsQueryDocument = gql`
    mutation UpdatePatientDemographicsQuery($input: UpdatePatientDemographicsQueryInput!) {
  updatePatientDemographicsQuery(input: $input) {
    created_patient_entry_index
    created_patient_id
  }
}
    `;
export type UpdatePatientDemographicsQueryMutationFn = Apollo.MutationFunction<UpdatePatientDemographicsQueryMutation, UpdatePatientDemographicsQueryMutationVariables>;

/**
 * __useUpdatePatientDemographicsQueryMutation__
 *
 * To run a mutation, you first call `useUpdatePatientDemographicsQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientDemographicsQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientDemographicsQueryMutation, { data, loading, error }] = useUpdatePatientDemographicsQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientDemographicsQueryMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientDemographicsQueryMutation, UpdatePatientDemographicsQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientDemographicsQueryMutation, UpdatePatientDemographicsQueryMutationVariables>(UpdatePatientDemographicsQueryDocument, options);
      }
export type UpdatePatientDemographicsQueryMutationHookResult = ReturnType<typeof useUpdatePatientDemographicsQueryMutation>;
export type UpdatePatientDemographicsQueryMutationResult = Apollo.MutationResult<UpdatePatientDemographicsQueryMutation>;
export type UpdatePatientDemographicsQueryMutationOptions = Apollo.BaseMutationOptions<UpdatePatientDemographicsQueryMutation, UpdatePatientDemographicsQueryMutationVariables>;
export const GetPatientPathwaysDocument = gql`
    query GetPatientPathways($patient_id: String!) {
  patientPathways(patient_id: $patient_id) {
    patientPathways {
      ...PatientPathway
    }
  }
}
    ${PatientPathwayFragmentDoc}`;

/**
 * __useGetPatientPathwaysQuery__
 *
 * To run a query within a React component, call `useGetPatientPathwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientPathwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientPathwaysQuery({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *   },
 * });
 */
export function useGetPatientPathwaysQuery(baseOptions: Apollo.QueryHookOptions<GetPatientPathwaysQuery, GetPatientPathwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientPathwaysQuery, GetPatientPathwaysQueryVariables>(GetPatientPathwaysDocument, options);
      }
export function useGetPatientPathwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientPathwaysQuery, GetPatientPathwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientPathwaysQuery, GetPatientPathwaysQueryVariables>(GetPatientPathwaysDocument, options);
        }
export type GetPatientPathwaysQueryHookResult = ReturnType<typeof useGetPatientPathwaysQuery>;
export type GetPatientPathwaysLazyQueryHookResult = ReturnType<typeof useGetPatientPathwaysLazyQuery>;
export type GetPatientPathwaysQueryResult = Apollo.QueryResult<GetPatientPathwaysQuery, GetPatientPathwaysQueryVariables>;
export const GetPatientsDocument = gql`
    query GetPatients($filters: FilterPatients, $pagination: PaginationParams, $sorting: SortingParams) {
  patients(filters: $filters, pagination: $pagination, sorting: $sorting) {
    patients {
      ...Patient
    }
    pagination {
      offset
      count
      total_count
    }
    sorting {
      field
      direction
    }
  }
}
    ${PatientFragmentDoc}`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
      }
export function useGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
        }
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;
export const GetDetailsForPublishedPathwayDefinitionsDocument = gql`
    query GetDetailsForPublishedPathwayDefinitions {
  getStatusForPublishedPathwayDefinitions {
    publishedPathwayDefinitions {
      id
      title
      version
      total_activities
      active_activities
      failed_activities
      total_stakeholders
      stakeholders_with_pending_activities_list
      total_patients
      patients_with_pending_activities
    }
  }
}
    `;

/**
 * __useGetDetailsForPublishedPathwayDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetDetailsForPublishedPathwayDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailsForPublishedPathwayDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailsForPublishedPathwayDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDetailsForPublishedPathwayDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetDetailsForPublishedPathwayDefinitionsQuery, GetDetailsForPublishedPathwayDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailsForPublishedPathwayDefinitionsQuery, GetDetailsForPublishedPathwayDefinitionsQueryVariables>(GetDetailsForPublishedPathwayDefinitionsDocument, options);
      }
export function useGetDetailsForPublishedPathwayDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailsForPublishedPathwayDefinitionsQuery, GetDetailsForPublishedPathwayDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailsForPublishedPathwayDefinitionsQuery, GetDetailsForPublishedPathwayDefinitionsQueryVariables>(GetDetailsForPublishedPathwayDefinitionsDocument, options);
        }
export type GetDetailsForPublishedPathwayDefinitionsQueryHookResult = ReturnType<typeof useGetDetailsForPublishedPathwayDefinitionsQuery>;
export type GetDetailsForPublishedPathwayDefinitionsLazyQueryHookResult = ReturnType<typeof useGetDetailsForPublishedPathwayDefinitionsLazyQuery>;
export type GetDetailsForPublishedPathwayDefinitionsQueryResult = Apollo.QueryResult<GetDetailsForPublishedPathwayDefinitionsQuery, GetDetailsForPublishedPathwayDefinitionsQueryVariables>;
export const GetPublishedPathwayDefinitionsDocument = gql`
    query GetPublishedPathwayDefinitions {
  publishedPathwayDefinitions {
    publishedPathwayDefinitions {
      id
      title
      version
      dataPointDefinitions {
        id
        key
        valueType
        category
        title
        optional
        possibleValues {
          label
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetPublishedPathwayDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetPublishedPathwayDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedPathwayDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedPathwayDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublishedPathwayDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPublishedPathwayDefinitionsQuery, GetPublishedPathwayDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedPathwayDefinitionsQuery, GetPublishedPathwayDefinitionsQueryVariables>(GetPublishedPathwayDefinitionsDocument, options);
      }
export function useGetPublishedPathwayDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedPathwayDefinitionsQuery, GetPublishedPathwayDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedPathwayDefinitionsQuery, GetPublishedPathwayDefinitionsQueryVariables>(GetPublishedPathwayDefinitionsDocument, options);
        }
export type GetPublishedPathwayDefinitionsQueryHookResult = ReturnType<typeof useGetPublishedPathwayDefinitionsQuery>;
export type GetPublishedPathwayDefinitionsLazyQueryHookResult = ReturnType<typeof useGetPublishedPathwayDefinitionsLazyQuery>;
export type GetPublishedPathwayDefinitionsQueryResult = Apollo.QueryResult<GetPublishedPathwayDefinitionsQuery, GetPublishedPathwayDefinitionsQueryVariables>;
export const GenerateRetoolEmbedUrlDocument = gql`
    query GenerateRetoolEmbedUrl($landingPageUuid: String!, $groupIds: [String!]!, $userInfo: UserInfoParams!) {
  generateRetoolEmbedUrl(
    userInfo: $userInfo
    groupIds: $groupIds
    landingPageUuid: $landingPageUuid
  ) {
    url
  }
}
    `;

/**
 * __useGenerateRetoolEmbedUrlQuery__
 *
 * To run a query within a React component, call `useGenerateRetoolEmbedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRetoolEmbedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRetoolEmbedUrlQuery({
 *   variables: {
 *      landingPageUuid: // value for 'landingPageUuid'
 *      groupIds: // value for 'groupIds'
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useGenerateRetoolEmbedUrlQuery(baseOptions: Apollo.QueryHookOptions<GenerateRetoolEmbedUrlQuery, GenerateRetoolEmbedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateRetoolEmbedUrlQuery, GenerateRetoolEmbedUrlQueryVariables>(GenerateRetoolEmbedUrlDocument, options);
      }
export function useGenerateRetoolEmbedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateRetoolEmbedUrlQuery, GenerateRetoolEmbedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateRetoolEmbedUrlQuery, GenerateRetoolEmbedUrlQueryVariables>(GenerateRetoolEmbedUrlDocument, options);
        }
export type GenerateRetoolEmbedUrlQueryHookResult = ReturnType<typeof useGenerateRetoolEmbedUrlQuery>;
export type GenerateRetoolEmbedUrlLazyQueryHookResult = ReturnType<typeof useGenerateRetoolEmbedUrlLazyQuery>;
export type GenerateRetoolEmbedUrlQueryResult = Apollo.QueryResult<GenerateRetoolEmbedUrlQuery, GenerateRetoolEmbedUrlQueryVariables>;
export const RetryActivityDocument = gql`
    mutation RetryActivity($input: RetryActivityInput!) {
  retryActivity(input: $input) {
    success
  }
}
    `;
export type RetryActivityMutationFn = Apollo.MutationFunction<RetryActivityMutation, RetryActivityMutationVariables>;

/**
 * __useRetryActivityMutation__
 *
 * To run a mutation, you first call `useRetryActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryActivityMutation, { data, loading, error }] = useRetryActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryActivityMutation(baseOptions?: Apollo.MutationHookOptions<RetryActivityMutation, RetryActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryActivityMutation, RetryActivityMutationVariables>(RetryActivityDocument, options);
      }
export type RetryActivityMutationHookResult = ReturnType<typeof useRetryActivityMutation>;
export type RetryActivityMutationResult = Apollo.MutationResult<RetryActivityMutation>;
export type RetryActivityMutationOptions = Apollo.BaseMutationOptions<RetryActivityMutation, RetryActivityMutationVariables>;
export const RetryAllApiCallsDocument = gql`
    mutation RetryAllApiCalls($input: RetryAllApiCallsInput!) {
  retryAllApiCalls(input: $input) {
    success
    code
  }
}
    `;
export type RetryAllApiCallsMutationFn = Apollo.MutationFunction<RetryAllApiCallsMutation, RetryAllApiCallsMutationVariables>;

/**
 * __useRetryAllApiCallsMutation__
 *
 * To run a mutation, you first call `useRetryAllApiCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllApiCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllApiCallsMutation, { data, loading, error }] = useRetryAllApiCallsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllApiCallsMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllApiCallsMutation, RetryAllApiCallsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllApiCallsMutation, RetryAllApiCallsMutationVariables>(RetryAllApiCallsDocument, options);
      }
export type RetryAllApiCallsMutationHookResult = ReturnType<typeof useRetryAllApiCallsMutation>;
export type RetryAllApiCallsMutationResult = Apollo.MutationResult<RetryAllApiCallsMutation>;
export type RetryAllApiCallsMutationOptions = Apollo.BaseMutationOptions<RetryAllApiCallsMutation, RetryAllApiCallsMutationVariables>;
export const RetryAllFailedApiCallsDocument = gql`
    mutation RetryAllFailedApiCalls($input: RetryAllFailedApiCallsInput!) {
  retryAllFailedApiCalls(input: $input) {
    success
    code
  }
}
    `;
export type RetryAllFailedApiCallsMutationFn = Apollo.MutationFunction<RetryAllFailedApiCallsMutation, RetryAllFailedApiCallsMutationVariables>;

/**
 * __useRetryAllFailedApiCallsMutation__
 *
 * To run a mutation, you first call `useRetryAllFailedApiCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllFailedApiCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllFailedApiCallsMutation, { data, loading, error }] = useRetryAllFailedApiCallsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllFailedApiCallsMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllFailedApiCallsMutation, RetryAllFailedApiCallsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllFailedApiCallsMutation, RetryAllFailedApiCallsMutationVariables>(RetryAllFailedApiCallsDocument, options);
      }
export type RetryAllFailedApiCallsMutationHookResult = ReturnType<typeof useRetryAllFailedApiCallsMutation>;
export type RetryAllFailedApiCallsMutationResult = Apollo.MutationResult<RetryAllFailedApiCallsMutation>;
export type RetryAllFailedApiCallsMutationOptions = Apollo.BaseMutationOptions<RetryAllFailedApiCallsMutation, RetryAllFailedApiCallsMutationVariables>;
export const RetryAllFailedWebhooksDocument = gql`
    mutation RetryAllFailedWebhooks($input: RetryAllFailedWebhookCallsInput!) {
  retryAllFailedWebhookCalls(input: $input) {
    success
  }
}
    `;
export type RetryAllFailedWebhooksMutationFn = Apollo.MutationFunction<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>;

/**
 * __useRetryAllFailedWebhooksMutation__
 *
 * To run a mutation, you first call `useRetryAllFailedWebhooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllFailedWebhooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllFailedWebhooksMutation, { data, loading, error }] = useRetryAllFailedWebhooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllFailedWebhooksMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>(RetryAllFailedWebhooksDocument, options);
      }
export type RetryAllFailedWebhooksMutationHookResult = ReturnType<typeof useRetryAllFailedWebhooksMutation>;
export type RetryAllFailedWebhooksMutationResult = Apollo.MutationResult<RetryAllFailedWebhooksMutation>;
export type RetryAllFailedWebhooksMutationOptions = Apollo.BaseMutationOptions<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>;
export const RetryAllFailedWebhookCallsForPathwayDefinitionDocument = gql`
    mutation RetryAllFailedWebhookCallsForPathwayDefinition($input: RetryAllFailedWebhookCallsForPathwayDefinitionInput!) {
  retryAllFailedWebhookCallsForPathwayDefinition(input: $input) {
    success
  }
}
    `;
export type RetryAllFailedWebhookCallsForPathwayDefinitionMutationFn = Apollo.MutationFunction<RetryAllFailedWebhookCallsForPathwayDefinitionMutation, RetryAllFailedWebhookCallsForPathwayDefinitionMutationVariables>;

/**
 * __useRetryAllFailedWebhookCallsForPathwayDefinitionMutation__
 *
 * To run a mutation, you first call `useRetryAllFailedWebhookCallsForPathwayDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllFailedWebhookCallsForPathwayDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllFailedWebhookCallsForPathwayDefinitionMutation, { data, loading, error }] = useRetryAllFailedWebhookCallsForPathwayDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllFailedWebhookCallsForPathwayDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllFailedWebhookCallsForPathwayDefinitionMutation, RetryAllFailedWebhookCallsForPathwayDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllFailedWebhookCallsForPathwayDefinitionMutation, RetryAllFailedWebhookCallsForPathwayDefinitionMutationVariables>(RetryAllFailedWebhookCallsForPathwayDefinitionDocument, options);
      }
export type RetryAllFailedWebhookCallsForPathwayDefinitionMutationHookResult = ReturnType<typeof useRetryAllFailedWebhookCallsForPathwayDefinitionMutation>;
export type RetryAllFailedWebhookCallsForPathwayDefinitionMutationResult = Apollo.MutationResult<RetryAllFailedWebhookCallsForPathwayDefinitionMutation>;
export type RetryAllFailedWebhookCallsForPathwayDefinitionMutationOptions = Apollo.BaseMutationOptions<RetryAllFailedWebhookCallsForPathwayDefinitionMutation, RetryAllFailedWebhookCallsForPathwayDefinitionMutationVariables>;
export const RetryAllWebhookCallsDocument = gql`
    mutation RetryAllWebhookCalls($input: RetryAllWebhookCallsInput!) {
  retryAllWebhookCalls(input: $input) {
    success
  }
}
    `;
export type RetryAllWebhookCallsMutationFn = Apollo.MutationFunction<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>;

/**
 * __useRetryAllWebhookCallsMutation__
 *
 * To run a mutation, you first call `useRetryAllWebhookCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllWebhookCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllWebhookCallsMutation, { data, loading, error }] = useRetryAllWebhookCallsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllWebhookCallsMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>(RetryAllWebhookCallsDocument, options);
      }
export type RetryAllWebhookCallsMutationHookResult = ReturnType<typeof useRetryAllWebhookCallsMutation>;
export type RetryAllWebhookCallsMutationResult = Apollo.MutationResult<RetryAllWebhookCallsMutation>;
export type RetryAllWebhookCallsMutationOptions = Apollo.BaseMutationOptions<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>;
export const RetryApiCallDocument = gql`
    mutation RetryApiCall($input: RetryApiCallInput!) {
  retryApiCall(input: $input) {
    success
    code
  }
}
    `;
export type RetryApiCallMutationFn = Apollo.MutationFunction<RetryApiCallMutation, RetryApiCallMutationVariables>;

/**
 * __useRetryApiCallMutation__
 *
 * To run a mutation, you first call `useRetryApiCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryApiCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryApiCallMutation, { data, loading, error }] = useRetryApiCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryApiCallMutation(baseOptions?: Apollo.MutationHookOptions<RetryApiCallMutation, RetryApiCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryApiCallMutation, RetryApiCallMutationVariables>(RetryApiCallDocument, options);
      }
export type RetryApiCallMutationHookResult = ReturnType<typeof useRetryApiCallMutation>;
export type RetryApiCallMutationResult = Apollo.MutationResult<RetryApiCallMutation>;
export type RetryApiCallMutationOptions = Apollo.BaseMutationOptions<RetryApiCallMutation, RetryApiCallMutationVariables>;
export const RetryPushToEmrDocument = gql`
    mutation RetryPushToEMR($input: RetryPushToEmrInput!) {
  retryPushToEmr(input: $input) {
    success
  }
}
    `;
export type RetryPushToEmrMutationFn = Apollo.MutationFunction<RetryPushToEmrMutation, RetryPushToEmrMutationVariables>;

/**
 * __useRetryPushToEmrMutation__
 *
 * To run a mutation, you first call `useRetryPushToEmrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryPushToEmrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryPushToEmrMutation, { data, loading, error }] = useRetryPushToEmrMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryPushToEmrMutation(baseOptions?: Apollo.MutationHookOptions<RetryPushToEmrMutation, RetryPushToEmrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryPushToEmrMutation, RetryPushToEmrMutationVariables>(RetryPushToEmrDocument, options);
      }
export type RetryPushToEmrMutationHookResult = ReturnType<typeof useRetryPushToEmrMutation>;
export type RetryPushToEmrMutationResult = Apollo.MutationResult<RetryPushToEmrMutation>;
export type RetryPushToEmrMutationOptions = Apollo.BaseMutationOptions<RetryPushToEmrMutation, RetryPushToEmrMutationVariables>;
export const RetryWebhookCallDocument = gql`
    mutation RetryWebhookCall($input: RetryWebhookCallInput!) {
  retryWebhookCall(input: $input) {
    success
    code
  }
}
    `;
export type RetryWebhookCallMutationFn = Apollo.MutationFunction<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>;

/**
 * __useRetryWebhookCallMutation__
 *
 * To run a mutation, you first call `useRetryWebhookCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryWebhookCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryWebhookCallMutation, { data, loading, error }] = useRetryWebhookCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryWebhookCallMutation(baseOptions?: Apollo.MutationHookOptions<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>(RetryWebhookCallDocument, options);
      }
export type RetryWebhookCallMutationHookResult = ReturnType<typeof useRetryWebhookCallMutation>;
export type RetryWebhookCallMutationResult = Apollo.MutationResult<RetryWebhookCallMutation>;
export type RetryWebhookCallMutationOptions = Apollo.BaseMutationOptions<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>;
export const GetScheduledStepsDocument = gql`
    query GetScheduledSteps($pathway_id: String!) {
  scheduledSteps(pathway_id: $pathway_id) {
    success
    steps {
      id
      parent_id
      name
      start_date
      status
    }
  }
}
    `;

/**
 * __useGetScheduledStepsQuery__
 *
 * To run a query within a React component, call `useGetScheduledStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledStepsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetScheduledStepsQuery(baseOptions: Apollo.QueryHookOptions<GetScheduledStepsQuery, GetScheduledStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledStepsQuery, GetScheduledStepsQueryVariables>(GetScheduledStepsDocument, options);
      }
export function useGetScheduledStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledStepsQuery, GetScheduledStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledStepsQuery, GetScheduledStepsQueryVariables>(GetScheduledStepsDocument, options);
        }
export type GetScheduledStepsQueryHookResult = ReturnType<typeof useGetScheduledStepsQuery>;
export type GetScheduledStepsLazyQueryHookResult = ReturnType<typeof useGetScheduledStepsLazyQuery>;
export type GetScheduledStepsQueryResult = Apollo.QueryResult<GetScheduledStepsQuery, GetScheduledStepsQueryVariables>;
export const GetScheduledTracksDocument = gql`
    query GetScheduledTracks($pathway_id: String!) {
  scheduledTracksForPathway(pathway_id: $pathway_id) {
    success
    scheduled_tracks {
      id
      title
      scheduled_date
    }
  }
}
    `;

/**
 * __useGetScheduledTracksQuery__
 *
 * To run a query within a React component, call `useGetScheduledTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledTracksQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetScheduledTracksQuery(baseOptions: Apollo.QueryHookOptions<GetScheduledTracksQuery, GetScheduledTracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledTracksQuery, GetScheduledTracksQueryVariables>(GetScheduledTracksDocument, options);
      }
export function useGetScheduledTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledTracksQuery, GetScheduledTracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledTracksQuery, GetScheduledTracksQueryVariables>(GetScheduledTracksDocument, options);
        }
export type GetScheduledTracksQueryHookResult = ReturnType<typeof useGetScheduledTracksQuery>;
export type GetScheduledTracksLazyQueryHookResult = ReturnType<typeof useGetScheduledTracksLazyQuery>;
export type GetScheduledTracksQueryResult = Apollo.QueryResult<GetScheduledTracksQuery, GetScheduledTracksQueryVariables>;
export const GetStakeholdersByPathwayDefinitionIdsDocument = gql`
    query GetStakeholdersByPathwayDefinitionIds($pathway_definition_ids: [String!]!) {
  stakeholdersByPathwayDefinitionIds(
    pathway_definition_ids: $pathway_definition_ids
  ) {
    stakeholders {
      id
      label {
        en
      }
    }
  }
}
    `;

/**
 * __useGetStakeholdersByPathwayDefinitionIdsQuery__
 *
 * To run a query within a React component, call `useGetStakeholdersByPathwayDefinitionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStakeholdersByPathwayDefinitionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStakeholdersByPathwayDefinitionIdsQuery({
 *   variables: {
 *      pathway_definition_ids: // value for 'pathway_definition_ids'
 *   },
 * });
 */
export function useGetStakeholdersByPathwayDefinitionIdsQuery(baseOptions: Apollo.QueryHookOptions<GetStakeholdersByPathwayDefinitionIdsQuery, GetStakeholdersByPathwayDefinitionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStakeholdersByPathwayDefinitionIdsQuery, GetStakeholdersByPathwayDefinitionIdsQueryVariables>(GetStakeholdersByPathwayDefinitionIdsDocument, options);
      }
export function useGetStakeholdersByPathwayDefinitionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStakeholdersByPathwayDefinitionIdsQuery, GetStakeholdersByPathwayDefinitionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStakeholdersByPathwayDefinitionIdsQuery, GetStakeholdersByPathwayDefinitionIdsQueryVariables>(GetStakeholdersByPathwayDefinitionIdsDocument, options);
        }
export type GetStakeholdersByPathwayDefinitionIdsQueryHookResult = ReturnType<typeof useGetStakeholdersByPathwayDefinitionIdsQuery>;
export type GetStakeholdersByPathwayDefinitionIdsLazyQueryHookResult = ReturnType<typeof useGetStakeholdersByPathwayDefinitionIdsLazyQuery>;
export type GetStakeholdersByPathwayDefinitionIdsQueryResult = Apollo.QueryResult<GetStakeholdersByPathwayDefinitionIdsQuery, GetStakeholdersByPathwayDefinitionIdsQueryVariables>;
export const StartPathwayDocument = gql`
    mutation StartPathway($input: StartPathwayInput!) {
  startPathway(input: $input) {
    pathway_id
  }
}
    `;
export type StartPathwayMutationFn = Apollo.MutationFunction<StartPathwayMutation, StartPathwayMutationVariables>;

/**
 * __useStartPathwayMutation__
 *
 * To run a mutation, you first call `useStartPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPathwayMutation, { data, loading, error }] = useStartPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPathwayMutation(baseOptions?: Apollo.MutationHookOptions<StartPathwayMutation, StartPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPathwayMutation, StartPathwayMutationVariables>(StartPathwayDocument, options);
      }
export type StartPathwayMutationHookResult = ReturnType<typeof useStartPathwayMutation>;
export type StartPathwayMutationResult = Apollo.MutationResult<StartPathwayMutation>;
export type StartPathwayMutationOptions = Apollo.BaseMutationOptions<StartPathwayMutation, StartPathwayMutationVariables>;
export const StopPathwayDocument = gql`
    mutation StopPathway($input: StopPathwayInput!) {
  stopPathway(input: $input) {
    success
  }
}
    `;
export type StopPathwayMutationFn = Apollo.MutationFunction<StopPathwayMutation, StopPathwayMutationVariables>;

/**
 * __useStopPathwayMutation__
 *
 * To run a mutation, you first call `useStopPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopPathwayMutation, { data, loading, error }] = useStopPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopPathwayMutation(baseOptions?: Apollo.MutationHookOptions<StopPathwayMutation, StopPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopPathwayMutation, StopPathwayMutationVariables>(StopPathwayDocument, options);
      }
export type StopPathwayMutationHookResult = ReturnType<typeof useStopPathwayMutation>;
export type StopPathwayMutationResult = Apollo.MutationResult<StopPathwayMutation>;
export type StopPathwayMutationOptions = Apollo.BaseMutationOptions<StopPathwayMutation, StopPathwayMutationVariables>;
export const StopTrackDocument = gql`
    mutation StopTrack($input: StopTrackInput!) {
  stopTrack(input: $input) {
    success
  }
}
    `;
export type StopTrackMutationFn = Apollo.MutationFunction<StopTrackMutation, StopTrackMutationVariables>;

/**
 * __useStopTrackMutation__
 *
 * To run a mutation, you first call `useStopTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopTrackMutation, { data, loading, error }] = useStopTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopTrackMutation(baseOptions?: Apollo.MutationHookOptions<StopTrackMutation, StopTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopTrackMutation, StopTrackMutationVariables>(StopTrackDocument, options);
      }
export type StopTrackMutationHookResult = ReturnType<typeof useStopTrackMutation>;
export type StopTrackMutationResult = Apollo.MutationResult<StopTrackMutation>;
export type StopTrackMutationOptions = Apollo.BaseMutationOptions<StopTrackMutation, StopTrackMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation UpdatePatient($input: UpdatePatientInput!) {
  updatePatient(input: $input) {
    patient {
      ...Patient
    }
  }
}
    ${PatientFragmentDoc}`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const WebhookCallsForPathwayDefinitionDocument = gql`
    query WebhookCallsForPathwayDefinition($pathway_definition_id: String!) {
  webhookCallsForPathwayDefinition(pathway_definition_id: $pathway_definition_id) {
    webhook_calls {
      ...WebhookCall
    }
  }
}
    ${WebhookCallFragmentDoc}`;

/**
 * __useWebhookCallsForPathwayDefinitionQuery__
 *
 * To run a query within a React component, call `useWebhookCallsForPathwayDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookCallsForPathwayDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookCallsForPathwayDefinitionQuery({
 *   variables: {
 *      pathway_definition_id: // value for 'pathway_definition_id'
 *   },
 * });
 */
export function useWebhookCallsForPathwayDefinitionQuery(baseOptions: Apollo.QueryHookOptions<WebhookCallsForPathwayDefinitionQuery, WebhookCallsForPathwayDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebhookCallsForPathwayDefinitionQuery, WebhookCallsForPathwayDefinitionQueryVariables>(WebhookCallsForPathwayDefinitionDocument, options);
      }
export function useWebhookCallsForPathwayDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebhookCallsForPathwayDefinitionQuery, WebhookCallsForPathwayDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebhookCallsForPathwayDefinitionQuery, WebhookCallsForPathwayDefinitionQueryVariables>(WebhookCallsForPathwayDefinitionDocument, options);
        }
export type WebhookCallsForPathwayDefinitionQueryHookResult = ReturnType<typeof useWebhookCallsForPathwayDefinitionQuery>;
export type WebhookCallsForPathwayDefinitionLazyQueryHookResult = ReturnType<typeof useWebhookCallsForPathwayDefinitionLazyQuery>;
export type WebhookCallsForPathwayDefinitionQueryResult = Apollo.QueryResult<WebhookCallsForPathwayDefinitionQuery, WebhookCallsForPathwayDefinitionQueryVariables>;
export const WebhookCallsForTenantDocument = gql`
    query WebhookCallsForTenant {
  webhookCallsForTenant {
    webhook_calls {
      ...WebhookCall
    }
  }
}
    ${WebhookCallFragmentDoc}`;

/**
 * __useWebhookCallsForTenantQuery__
 *
 * To run a query within a React component, call `useWebhookCallsForTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookCallsForTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookCallsForTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebhookCallsForTenantQuery(baseOptions?: Apollo.QueryHookOptions<WebhookCallsForTenantQuery, WebhookCallsForTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebhookCallsForTenantQuery, WebhookCallsForTenantQueryVariables>(WebhookCallsForTenantDocument, options);
      }
export function useWebhookCallsForTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebhookCallsForTenantQuery, WebhookCallsForTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebhookCallsForTenantQuery, WebhookCallsForTenantQueryVariables>(WebhookCallsForTenantDocument, options);
        }
export type WebhookCallsForTenantQueryHookResult = ReturnType<typeof useWebhookCallsForTenantQuery>;
export type WebhookCallsForTenantLazyQueryHookResult = ReturnType<typeof useWebhookCallsForTenantLazyQuery>;
export type WebhookCallsForTenantQueryResult = Apollo.QueryResult<WebhookCallsForTenantQuery, WebhookCallsForTenantQueryVariables>;
export const WhoamiDocument = gql`
    query Whoami {
  whoami {
    user {
      id
      tenant_id
      profile {
        email
        name
      }
      tenant {
        is_default
        name
        identifier_systems {
          name
          display_name
          system
        }
      }
    }
  }
}
    `;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoamiQuery(baseOptions?: Apollo.QueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
      }
export function useWhoamiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
        }
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<WhoamiQuery, WhoamiQueryVariables>;
export const PageTitleDocument = gql`
    query PageTitle {
  pageTitle @client
}
    `;

/**
 * __usePageTitleQuery__
 *
 * To run a query within a React component, call `usePageTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageTitleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageTitleQuery(baseOptions?: Apollo.QueryHookOptions<PageTitleQuery, PageTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageTitleQuery, PageTitleQueryVariables>(PageTitleDocument, options);
      }
export function usePageTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageTitleQuery, PageTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageTitleQuery, PageTitleQueryVariables>(PageTitleDocument, options);
        }
export type PageTitleQueryHookResult = ReturnType<typeof usePageTitleQuery>;
export type PageTitleLazyQueryHookResult = ReturnType<typeof usePageTitleLazyQuery>;
export type PageTitleQueryResult = Apollo.QueryResult<PageTitleQuery, PageTitleQueryVariables>;
export const PathwayLayoutDocument = gql`
    query PathwayLayout {
  pathwayLayout @client {
    activityFeedWidth
  }
}
    `;

/**
 * __usePathwayLayoutQuery__
 *
 * To run a query within a React component, call `usePathwayLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function usePathwayLayoutQuery(baseOptions?: Apollo.QueryHookOptions<PathwayLayoutQuery, PathwayLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayLayoutQuery, PathwayLayoutQueryVariables>(PathwayLayoutDocument, options);
      }
export function usePathwayLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayLayoutQuery, PathwayLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayLayoutQuery, PathwayLayoutQueryVariables>(PathwayLayoutDocument, options);
        }
export type PathwayLayoutQueryHookResult = ReturnType<typeof usePathwayLayoutQuery>;
export type PathwayLayoutLazyQueryHookResult = ReturnType<typeof usePathwayLayoutLazyQuery>;
export type PathwayLayoutQueryResult = Apollo.QueryResult<PathwayLayoutQuery, PathwayLayoutQueryVariables>;